import React, { useRef } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import { Link } from "react-router-dom";
import { HiMiniRocketLaunch } from "react-icons/hi2";
import { MdArrowBackIosNew, MdArrowForwardIos } from "react-icons/md";
import { gameData } from "../helpers/gamelist";
import { useTranslation } from "react-i18next";
import i18n from "../../src/i18n/i18n";
import { LazyLoadImage } from "react-lazy-load-image-component";

const GameCarousel = ({ game, i18n: i18nInstance }) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`carousel.${key}`);
  const currentLang = i18nInstance.language || "en";
  const currentGameData = gameData(currentLang);

  const splideRef = useRef(null);

  const slideLeft = () => {
    if (splideRef.current) {
      splideRef.current.go("<"); // 向左滑动一个幻灯片
    }
  };

  const slideRight = () => {
    if (splideRef.current) {
      splideRef.current.go(">"); // 向右滑动一个幻灯片
    }
  };

  return (
    <section className="text-center">
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center">
          <HiMiniRocketLaunch className="text-main-color text-xl mr-2" />
          <h2 className="text-text-second-gray font-extrabold text-base">
            New Products
          </h2>
        </div>
        <div className="flex flex-row items-center">
          <div
            className="rounded-xl splide-arrow-button h-[35px] w-[35px] mr-2 flex justify-center items-center"
            onClick={slideLeft}
          >
            <MdArrowBackIosNew className="text-text-second-gray text-xl p-1" />
          </div>
          <div
            className="rounded-xl splide-arrow-button h-[35px] w-[35px] mr-2 flex justify-center items-center"
            onClick={slideRight}
          >
            <MdArrowForwardIos className="text-text-second-gray text-xl p-1 " />
          </div>
        </div>
      </div>
      <Splide
        ref={splideRef}
        aria-label="New Products"
        className=" w-full overflow-hidden"
        options={{
          type: "slide",
          perPage: 7,
          gap: 15,
          autoplay: false,
          arrows: false,
          drag: "free",
          perMove: 1,
          pagination: false,
          lazyLoad: "nearby",
          dragMinThreshold: {
            mouse: 0,
            touch: 10,
          },
          preloadPages: 7,
          keyboard: false,
          breakpoints: {
            640: {
              perPage: 3,
            },
            768: {
              perPage: 5,
            },
            1024: {
              perPage: 5,
            },
            1280: {
              perPage: 7,
            },
          },
        }}
      >
        {currentGameData.map((game, index) => (
          <SplideSlide key={index} className="rounded-xl">
            <div className="gameSlide-container">
              <LazyLoadImage
                src={game.image}
                alt={game.title}
                data-splide-lazy="path-to-the-image"
                className="rounded-xl object-cover w-full h-[180px]"
              />
              <div className="overlay">
                <div className="overlay-content">
                  <p>{game.title}</p>
                  <Link to={`/play/${encodeURIComponent(game.Demolink)}`}>
                    <div className="play-button rounded-[5px] !px-[8px] !py-[6px] !mb-2">
                      {i18n("Play Demo")}
                    </div>
                  </Link>
                  <Link to={game.Morelink}>
                    <div className="more-button rounded-[5px] !px-[8px] !py-[6px]">
                      {i18n("Read More")}
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </SplideSlide>
        ))}
      </Splide>
    </section>
  );
};

export default GameCarousel;
