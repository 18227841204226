import React from "react";
import { stylesConfig } from "../helpers/stylesConfig";
import { useTranslation } from "react-i18next";
import i18n from "../../src/i18n/i18n";

import footerIcon1 from "../images/footer/sigma.png";
import footerIcon2 from "../images/footer/bmm.png";
import footerIcon3 from "../images/footer/antillephone.png";
import footerIcon4 from "../images/footer/gli.png";
import footerIcon5 from "../images/footer/itech.png";
import { Link } from "react-router-dom";
import { IoMail, IoMailOutline } from "react-icons/io5";
import { BiLogoTelegram } from "react-icons/bi";
import { PiTelegramLogoLight } from "react-icons/pi";
import ShareButton from "./ShareButton";
import { COMMON_ALT } from "../constant";

const Footer = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`footer.${key}`);

  const footerIcons = [
    { text: "sigma", icon: footerIcon1, link: "https://sigma.world/" },
    { text: "bmm", icon: footerIcon2, link: "https://bmm.com/" },
    { text: "antillephone", icon: footerIcon3 },
    { text: "gli", icon: footerIcon4 },
    {
      text: "itech",
      icon: footerIcon5,
      link: "https://gaminglabs.com/gli-certified-mark/",
    },
  ];

  return (
    <section className="flex px-4 md:px-16 py-10 bg-[#fec2c2] text-center border-t-2 border-t-base-white">
      <div className="w-full mx-auto box-border block py-4 ">
        <div className="flex flex-col md:flex-row items-center gap-4 justify-between">
          <div className="flex flex-col md:flex-row items-center gap-4">
            <img
              alt={COMMON_ALT}
              src={stylesConfig.footerLogo}
              className="w-[150px] mr-8 logo-drop-shadow"
            />
            <div className="flex justify-center items-center gap-4">
              {footerIcons.map((item, index) =>
                item.link ? (
                  <Link to={item.link} target="_blank" key={index}>
                    <div className="relative flex items-center justify-center w-[60px] h-[60px]">
                      <img
                        src={item.icon}
                        className="w-[40px]"
                        alt={item.text}
                      />
                    </div>
                  </Link>
                ) : (
                  <div
                    className="relative flex items-center justify-center w-[60px] h-[60px]"
                    key={index}
                  >
                    <img src={item.icon} className="w-[40px]" alt={item.text} />
                  </div>
                )
              )}
            </div>
          </div>
          {/* <span className="text-text-third-color font-normal text-sm text-center">
            {i18n("footerText")}
          </span> */}
          <div className="flex flex-col md:flex-row justify-center items-center gap-4">
            <h2 class="text-white md:text-text-second-gray font-semibold md:font-normal text-xl">
              {i18n("Contact Us")}
            </h2>
            <div className="flex items-center justify-center gap-4">
              <ShareButton />
            </div>
            {/* <div className="flex gap-4 justify-center items-center">
              <Link
                to="https://www.facebook.com/eazygaming/"
                target="_blank"
                className="community-icon rounded-[50%] w-[55px] h-[55px] p-2 m-2 flex justify-center items-center"
              >
                <BiLogoTelegram className="text-3xl text-gray-button-bg" />
              </Link>
              <Link
                to="https://www.facebook.com/eazygaming/"
                target="_blank"
                className="community-icon rounded-[50%] w-[55px] h-[55px] p-2 m-2 flex justify-center items-center"
              >
                <IoMail className="text-3xl text-gray-button-bg" />
              </Link>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
