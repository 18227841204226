import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { languageOptions, languageMapping } from "../../i18n/config";
import { COMMON_WEB_PATH } from "../../helpers/stylesConfig";

const LanguageSettings = () => {
  const { t, i18n } = useTranslation();
  const CAROUSEL_PATH = `${COMMON_WEB_PATH}/icon`;

  const options = languageOptions.map((option) => ({
    value: option.value,
    label: languageMapping(option.value),
  }));

  const handleChangeLanguage = (event) => {
    i18n.changeLanguage(event.target.value);
  };
  return (
    <div className="cursor-pointer relative">
      <img
        src={`${CAROUSEL_PATH}/languageICON.webp`}
        alt="language"
        className="w-4 h-4 z-[2] absolute left-[10%] top-[48%] translate-y-[-50%]"
      />
      <select
        onChange={handleChangeLanguage}
        value={i18n.language}
        className="dropdown-menu px-3 md:pl-[36px] md:px-5 py-1.5 md:py-3 rounded-[12px] cursor-pointer"
      >
        {options.map((option) => (
          <option
            className="cursor-pointer"
            key={option.value}
            value={option.value}
          >
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default LanguageSettings;
