import React, { useState } from "react";
import { Link } from "react-router-dom";
import { stylesConfig } from "../helpers/stylesConfig";
import MenuItem from "../pages/components/MenuBar/MenuItem";
import LanguageSettings from "../pages/components/LanguageSettings";
import { HiMenu } from "react-icons/hi";
import MenuContent from "./MenuContent";
import { motion, AnimatePresence } from "framer-motion";
import menuIcon from "../images/icon/menuICON.webp";

const MenuMobile = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  return (
    <AnimatePresence>
      <div className="mobile_show">
        <motion.div className="" onClick={toggleMenu}>
          {/* <HiMenu className="text-text-second-gray text-base cursor-pointer" /> */}
          <img
            src={menuIcon}
            alt="menu"
            className="w-[30px] h-[30px] cursor-pointer"
          />
        </motion.div>
        {isMenuOpen && <MenuContent onClose={toggleMenu} />}
        {/* 傳遞 onClose 属性 */}
      </div>
    </AnimatePresence>
  );
};

export default MenuMobile;
