import React, { forwardRef, useRef } from "react";
import WrapperTitle from "./WrapperTitle";
import { BiSolidPhone } from "react-icons/bi";
import { BsPersonFill, BsFillBuildingFill } from "react-icons/bs";
import { IoMail } from "react-icons/io5";
import { Button, Form, message, Input, Space } from "antd";
import SpecText from "./SpecText";
import emailjs from "emailjs-com";
import { useTranslation } from "react-i18next";
import i18n from "../../src/i18n/i18n";
import contactBG from "../images/bg/contactBG.webp";
import contactBG_mobile from "../images/bg/contactBG_mobile.webp";
import contactICON from "../images/icon/contactICON.webp";
import nameICON from "../images/icon/name.webp";
import companyICON from "../images/icon/company.webp";
import emailICON from "../images/icon/email.webp";
import phoneICON from "../images/icon/phone.webp";
import { COMMON_ALT } from "../constant";
import { COMMON_WEB_PATH } from "../helpers/stylesConfig";

const ContactV2 = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`common.${key}`);
  const IMAGE_PATH = `${COMMON_WEB_PATH}/bg`;
  const ICON_PATH = `${COMMON_WEB_PATH}/icon`;

  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_lv5v4a1",
        "template_frsan7q",
        form.current,
        "CUOsBqNSzl_dSYuQo"
      )
      .then(
        (result) => {
          // console.log(result.text);
          message.success(i18n("SUCCESS!"));
        },
        (error) => {
          // console.log(error.text);
          message.error(i18n("FAILED..."));
        }
      );
  };

  return (
    <section ref={ref} className="relative">
      {/* <WrapperTitle icon={<BiSolidPhone />} title={i18n("Contact Us")} /> */}
      <img
        src={`${ICON_PATH}/contactICON.webp`}
        alt={COMMON_ALT}
        className="absolute lg:right-auto right-0 sm:right-[-10px] lg:left-0 top-14 lg:top-0 xl:top-[-10px] 2xl:top-2 w-[120px] xl:w-[130px] 2xl:w-[150px] h-auto object-cover rounded-xl block z-[2]"
      />
      <img
        src={`${IMAGE_PATH}/contactBG.webp`}
        alt={COMMON_ALT}
        className="w-full h-full object-cover rounded-xl hidden lg:block my-2 lg:my-10"
      />
      <div
        style={{ backgroundImage: `url(${IMAGE_PATH}/contactBG_mobile.webp)` }}
        className="relative lg:absolute lg:!bg-none rounded-3xl top-0 left-0 w-full h-full bg-cover bg-center px-6 sm:px-6 md:px-6 lg:px-20 xl:px-20 2xl:px-28 3xl:px-36 py-6 lg:py-20 xl:py-24 2xl:py-36 flex flex-col-reverse lg:flex-row justify-between items-center gap-x-8 gap-y-4"
      >
        <form className="flex-1" ref={form} onSubmit={sendEmail}>
          <div className="flex flex-col gap-3">
            <div className="w-full flex justify-between gap-2">
              <div className="inline-flex flex-col relative w-full">
                <div>
                  <Input
                    type="text"
                    name="name"
                    className="inline-flex items-center input-border cursor-text rounded-xl p-3 text-text-second-gray bg-[#ffd5ce]"
                    size="large"
                    placeholder={i18n("Your Name")}
                    prefix={
                      //   <BsPersonFill className="text-input-icon text-2xl mr-2" />
                      <img
                        src={`${ICON_PATH}/name.webp`}
                        alt="User Icon"
                        className="w-6 h-6 mr-2"
                      />
                    }
                  />
                </div>
              </div>
              <div className="inline-flex flex-col relative w-full">
                <div>
                  <Input
                    type="text"
                    name="company"
                    className="inline-flex items-center input-border cursor-text rounded-xl p-3 text-text-second-gray bg-[#ffd5ce]"
                    size="large"
                    placeholder={i18n("Company")}
                    prefix={
                      //   <BsFillBuildingFill className="text-input-icon text-2xl mr-2" /><img
                      <img
                        src={`${ICON_PATH}/company.webp`}
                        alt="Company"
                        className="w-6 h-6 mr-2"
                      />
                    }
                  />
                </div>
              </div>
            </div>
            <div className="inline-flex flex-col relative w-full">
              <div>
                <Input
                  type="email"
                  name="email"
                  className="inline-flex items-center input-border cursor-text rounded-xl p-3 text-text-second-gray bg-[#ffd5ce]"
                  size="large"
                  placeholder={i18n("Email")}
                  prefix={
                    <img
                      src={`${ICON_PATH}/email.webp`}
                      alt="email"
                      className="w-6 h-6 mr-2"
                    />
                    //   <IoMail className="text-input-icon text-2xl mr-2" />
                  }
                />
              </div>
            </div>
            <div className="inline-flex flex-col relative w-full">
              <div>
                <Input
                  type="text"
                  name="phone"
                  className="inline-flex items-center input-border cursor-text rounded-xl p-3 text-text-second-gray bg-[#ffd5ce]"
                  size="large"
                  placeholder={i18n("Phone")}
                  prefix={
                    // <BiSolidPhone className="text-input-icon text-2xl mr-2" />
                    <img
                      src={`${ICON_PATH}/phone.webp`}
                      alt="phone"
                      className="w-6 h-6 mr-2"
                    />
                  }
                />
              </div>
            </div>
            <Button
              type="submit"
              htmlType="submit"
              className="more-button rounded-[5px] px-[10px] py-[5px] h-auto"
            >
              {i18n("Submit")}
            </Button>
          </div>
        </form>
        <div className="flex flex-col flex-1 gap-3.5">
          <h1 className="text-white text-3xl md:text-4xl font-bold">
            {i18n("Get our exclusive offer!")}
          </h1>
          <ul className="flex flex-col gap-2 pl-5 md:pl-5">
            <li className="text-white text-base font-normal py-1.5 li-dot">
              <SpecText> {i18n("Seamless API")} </SpecText>
              {i18n("integration")}.
            </li>
            <li className="text-white text-base font-normal py-1.5 li-dot">
              {i18n("Supports multiple")}
              <SpecText> {i18n("currencies")} </SpecText>
              {i18n("and")}
              <SpecText> {i18n("languages")}</SpecText>.
            </li>
            <li className="text-white text-base font-normal py-1.5 li-dot">
              <SpecText>{i18n("Responsive")} </SpecText>
              {i18n("on most devices")}.
            </li>
            <li className="text-white text-base font-normal py-1.5 li-dot">
              {i18n("Optimized for")}
              <SpecText> {i18n("low-end devices")} </SpecText>
              {i18n("with")}
              <SpecText> {i18n("low bandwidth")} </SpecText>
              {i18n("usage")}.
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
});

export default ContactV2;
