import React from "react";
import { Link } from "react-router-dom";
import { COMMON_WEB_PATH, stylesConfig } from "../helpers/stylesConfig";
import introImg from "../images/game/B2C.webp";
import SpecText from "./SpecText";
import { useTranslation } from "react-i18next";
import i18n from "../../src/i18n/i18n";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { COMMON_ALT } from "../constant";
import experienceBG from "../images/bg/experienceBG.webp";
import experienceBG_mobile from "../images/bg/experienceBG_mobile.webp";

const IntroBlock = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`common.${key}`);
  const IMAGE_PATH = `${COMMON_WEB_PATH}/bg`;

  return (
    <section
      className="relative flex flex-col md:flex-row rounded-xl items-center pb-[5%]"
      // style={{
      //   backgroundImage: `url(${experienceBG})`,
      //   backgroundPosition: "center",
      //   backgroundRepeat: "no-repeat",
      //   backgroundSize: "cover",
      // }}
    >
      <img
        src={`${IMAGE_PATH}/experienceBG.webp`}
        alt={COMMON_ALT}
        className="w-full h-full object-cover rounded-xl hidden sm:block"
      />
      <div
        className="relative sm:absolute top-0 left-0 w-full h-full sm:!bg-none rounded-[20px] md:rounded-0"
        style={{
          backgroundImage: `url(${IMAGE_PATH}/experienceBG_mobile.webp)`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="flex flex-col flex-1 items-end justify-start gap-2 lg:gap-4 py-[9%] sm:py-[7%] md:py-[7%] lg:py-[8%] xl:py-[8%] pb-[100%] sm:pb-auto pl-[5%] pr-[5%] sm:pr-[0%] sm:pr-auto sm:pl-[12%] w-full max-w-none md:max-w-[58%] xl:max-w-[60%] mx-[0%] ">
          <div className="flex justify-start w-full text-left mb-3">
            <img
              src={stylesConfig.headerLogo}
              className="w-[100px] sm:w-[100px] md:w-[100px] lg:w-[150px] xl:w-[180px] 2xl:w-[220px] text-center"
              alt={COMMON_ALT}
            />
          </div>
          <div className="flex flex-col w-full ">
            <h1 className="text-light-black sm:text-white text-2xl sm:text-2xl md:text-2xl lg:text-2xl xl:text-3xl 2xl:text-4xl font-semibold mb-2 xl:mb-4">
              {i18n("UNRIVALLED GAMING EXPERIENCE")}
            </h1>
            <h4 className="text-[#32344c] md:text-white text-sm md:text-lg block sm:hidden lg:block">
              {i18n(
                "The industry want great games, and we have a ready-made advantage."
              )}{" "}
              {/* <SpecText>Eazy Gaming</SpecText> */}
            </h4>
            <h4 className="text-[#32344c] md:text-white text-sm md:text-lg block sm:hidden lg:hidden xl:block">
              {i18n(
                "Embrace top-tier game design and innovative technology in the industry, and immerse yourself in an unparalleled gaming adventure!"
              )}{" "}
            </h4>
          </div>
          {/* <Link
          to="/"
          className="flex justify-center items-center self-center md:self-start"
        >
          <div class="more-button rounded-[5px] px-[10px] py-[5px]">
            {i18n("Go to Website")}
          </div>
        </Link> */}
        </div>
      </div>
      {/* <div className="flex flex-col flex-1 items-end justify-end rounded-2xl overflow-hidden">
        <LazyLoadImage src={introImg} className="w-full max-w-[415px]" />
      </div> */}
    </section>
  );
};

export default IntroBlock;
