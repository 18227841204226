import React from "react";
import { Link, useLocation } from "react-router-dom";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, message, Space } from "antd";
import {
  IoHome,
  IoGameController,
  IoPlanet,
  IoTrophy,
  IoChevronForwardOutline,
  IoChevronDownOutline,
} from "react-icons/io5";
import { TbPokerChip } from "react-icons/tb";
import { SiBlockchaindotcom } from "react-icons/si";
import { FaFish } from "react-icons/fa";
import { PiCertificateFill } from "react-icons/pi";
import { BsShieldFillCheck } from "react-icons/bs";
import { MdCasino } from "react-icons/md";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n/i18n";
import homeIMG from "../../../images/icon/home.webp";
import fairIMG from "../../../images/icon/fair.webp";
import aboutIMG from "../../../images/icon/about.webp";
import licenseIMG from "../../../images/icon/license.webp";
import home_active from "../../../images/icon/home_active.webp";
import fair_active from "../../../images/icon/fair_active.webp";
import about_active from "../../../images/icon/about_active.webp";
import license_active from "../../../images/icon/license_active.webp";
import { COMMON_WEB_PATH } from "../../../helpers/stylesConfig";

const MenuItem = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const i18n = (key) => t(`header.${key}`);
  const ICON_PATH = `${COMMON_WEB_PATH}/icon`;

  const itemLinks = [
    {
      to: "/",
      text: i18n("Home"),
      icon: <IoHome />,
      image: `${ICON_PATH}/home.webp`,
      activeImage: `${ICON_PATH}/home_active.webp`,
    },
    {
      to: "/company/about",
      text: i18n("About Us"),
      image: `${ICON_PATH}/about.webp`,
      activeImage: `${ICON_PATH}/about_active.webp`,
    },
    {
      to: "/company/provablyFair",
      text: i18n("Provably Fair"),
      image: `${ICON_PATH}/fair.webp`,
      activeImage: `${ICON_PATH}/fair_active.webp`,
    },
    {
      to: "/company/licenses",
      text: i18n("Licenses"),
      image: `${ICON_PATH}/license.webp`,
      activeImage: `${ICON_PATH}/license_active.webp`,
    },
    //{ to: "/products", text: i18n("Products"), icon: <IoGameController /> },
  ];

  const productItems = [
    {
      label: (
        <Link to="/products" className="text-base font-bold">
          {i18n("All Games")}
        </Link>
      ),
      key: "0",
      icon: <IoGameController className="mr-2 !text-2xl" />,
      size: "large",
    },
    {
      label: (
        <Link to="/products/Slot" className="text-base font-bold">
          {i18n("Slot")}
        </Link>
      ),
      key: "1",
      icon: <TbPokerChip className="mr-2 !text-2xl" />,
      size: "large",
    },
    {
      label: (
        <Link to="/products/Blockchain" className="text-base font-bold">
          {i18n("Blockchain")}
        </Link>
      ),
      key: "4",
      icon: <SiBlockchaindotcom className="mr-2 !text-2xl" />,
      size: "large",
    },
    {
      label: (
        <Link to="/products/SICBO" className="text-base font-bold">
          {i18n("SICBO")}
        </Link>
      ),
      key: "3",
      icon: <MdCasino className="mr-2 !text-2xl" />,
      size: "large",
    },
    // {
    //   label: (
    //     <Link to="/products/Fish" className="text-base font-bold">
    //       {i18n("Fish")}
    //     </Link>
    //   ),
    //   key: "2",
    //   icon: <FaFish className="mr-2 !text-2xl" />,
    //   size: "large",
    // },
  ];
  const companyItems = [
    {
      label: (
        <Link to="/company/about" className="text-base font-bold">
          {i18n("About Us")}
        </Link>
      ),
      key: "1",
      icon: <IoTrophy className="mr-2 !text-2xl" />,
      size: "large",
    },
    {
      label: (
        <Link to="/company/provablyFair" className="text-base font-bold">
          {i18n("Provably Fair")}
        </Link>
      ),
      key: "1",
      icon: <BsShieldFillCheck className="mr-2 !text-2xl" />,
      size: "large",
    },
    {
      label: (
        <Link to="/company/licenses" className="text-base font-bold">
          {i18n("Licenses")}
        </Link>
      ),
      key: "2",
      icon: <PiCertificateFill className="mr-2 !text-2xl" />,
      size: "large",
    },
  ];

  return (
    <section className="gap-[10px] justify-between items-center hidden md:flex">
      <div className="flex gap-[10px]">
        {itemLinks.map((item, index) => {
          const isActive = location.pathname === item.to; // 判斷是否為當前頁面
          return (
            <Link
              key={index}
              to={item.to}
              className={`group flex items-center menu-font font-bold ${
                isActive ? "text-black" : "text-text-second-gray"
              } hover:text-black p-3 rounded-[15px] hover:bg-hover-mask-bg hover-bg-transition`}
            >
              {/* <span className="mr-2 text-2xl">{item.icon}</span> */}
              <img
                src={isActive ? item.activeImage : item.image}
                alt={item.text}
                className={`mr-2 w-6 h-6 ${
                  isActive ? "" : "group-hover:hidden"
                }`}
              />
              <img
                src={item.activeImage}
                alt={item.text}
                className={`mr-2 w-6 h-6 ${
                  isActive ? "hidden" : "hidden group-hover:block"
                }`}
              />
              {item.text}
            </Link>
          );
        })}
        {/* <Dropdown
          size="large"
          menu={{ items: productItems }}
          className="cursor-pointer"
          trigger={["hover"]}
        >
          <a onClick={(e) => e.preventDefault()}>
            <Space className="menu-font font-bold text-text-second-gray hover:text-main-color p-3 rounded-[5px] hover:bg-hover-mask-bg hover-bg-transition">
              <IoGameController className="mr-2 text-2xl" />
              {i18n("Products")}
              <DownOutlined />
            </Space>
          </a>
        </Dropdown>
        <Dropdown
          size="large"
          menu={{ items: companyItems }}
          className="cursor-pointer"
          trigger={["hover"]}
        >
          <a onClick={(e) => e.preventDefault()}>
            <Space className="menu-font font-bold text-text-second-gray hover:text-main-color p-3 rounded-[5px] hover:bg-hover-mask-bg hover-bg-transition">
              <IoPlanet className="mr-2 text-2xl" />
              {i18n("Company")}
              <DownOutlined />
            </Space>
          </a>
        </Dropdown> */}
      </div>
    </section>
  );
};

export default MenuItem;
