import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, message, Space } from "antd";
import {
  IoHome,
  IoGameController,
  IoPlanet,
  IoTrophy,
  IoChevronForwardOutline,
  IoChevronDownOutline,
} from "react-icons/io5";
import { TbPokerChip } from "react-icons/tb";
import { SiBlockchaindotcom } from "react-icons/si";
import { FaFish } from "react-icons/fa";
import { PiCertificateFill } from "react-icons/pi";
import { BsShieldFillCheck } from "react-icons/bs";
import { MdArrowDropDown, MdArrowDropUp, MdCasino } from "react-icons/md";
import { useTranslation } from "react-i18next";
import i18n from "../i18n/i18n";
import homeIMG from "../images/icon/home.webp";
import fairIMG from "../images/icon/fair.webp";
import aboutIMG from "../images/icon/about.webp";
import licenseIMG from "../images/icon/license.webp";
import home_active from "../images/icon/home_active.webp";
import fair_active from "../images/icon/fair_active.webp";
import about_active from "../images/icon/about_active.webp";
import license_active from "../images/icon/license_active.webp";
import products_active from "../images/icon/product_active.webp";
import company_active from "../images/icon/company_active.webp";
import { COMMON_WEB_PATH } from "../helpers/stylesConfig";
const MenuContentInner = ({ onClose }) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`header.${key}`);
  const IMAGE_PATH = `${COMMON_WEB_PATH}/icon`;
  //const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isProductDropdownOpen, setIsProductDropdownOpen] = useState(false);
  const [isCompanyDropdownOpen, setIsCompanyDropdownOpen] = useState(false);
  // 管理各自Dropdown的展開/摺疊狀態

  const [expandedCategory, setExpandedCategory] = useState(null);

  const toggleCategory = (category) => {
    if (expandedCategory === category) {
      setExpandedCategory(null);
    } else {
      setExpandedCategory(category);
    }
  };

  const location = useLocation();

  const itemLinks = [
    {
      to: "/",
      text: i18n("Home"),
      icon: <IoHome />,
      image: homeIMG,
      activeImage: home_active,
    },
    // {
    //   to: "/company/about",
    //   text: i18n("About Us"),
    //   image: aboutIMG,
    //   activeImage: about_active,
    // },
    // {
    //   to: "/company/provablyFair",
    //   text: i18n("Provably Fair"),
    //   image: fairIMG,
    //   activeImage: fair_active,
    // },
    // {
    //   to: "/company/licenses",
    //   text: i18n("Licenses"),
    //   image: licenseIMG,
    //   activeImage: license_active,
    // },
    //{ to: "/products", text: i18n("Products"), icon: <IoGameController /> },
  ];

  const companyItems = [
    {
      label: (
        <Link
          to="/company/about"
          onClick={onClose}
          className="text-base font-bold"
        >
          {i18n("About Us")}
        </Link>
      ),
      key: "1",
      icon: <IoTrophy className="mr-2 !text-2xl" />,
      image: `${IMAGE_PATH}/about.webp`,
      activeImage: `${IMAGE_PATH}/about_active.webp`,
      size: "large",
    },
    {
      label: (
        <Link
          to="/company/provablyFair"
          onClick={onClose}
          className="text-base font-bold"
        >
          {i18n("Provably Fair")}
        </Link>
      ),
      key: "1",
      icon: <BsShieldFillCheck className="mr-2 !text-2xl" />,
      image: `${IMAGE_PATH}/fair.webp`,
      activeImage: `${IMAGE_PATH}/fair_active.webp`,
      size: "large",
    },
    {
      label: (
        <Link
          to="/company/licenses"
          onClick={onClose}
          className="text-base font-bold"
        >
          {i18n("Licenses")}
        </Link>
      ),
      key: "2",
      icon: <PiCertificateFill className="mr-2 !text-2xl" />,
      image: `${IMAGE_PATH}/license.webp`,
      activeImage: `${IMAGE_PATH}/license_active.webp`,
      size: "large",
    },
  ];
  const productItems = [
    {
      label: (
        <Link to="/products" onClick={onClose} className="text-base font-bold">
          {i18n("All Games")}
        </Link>
      ),
      key: "0",
      icon: <IoGameController className="mr-2 !text-2xl text-[#8e79ae]" />,
      size: "large",
    },
    {
      label: (
        <Link
          to="/products/Slot"
          onClick={onClose}
          className="text-base font-bold"
        >
          {i18n("Slot")}
        </Link>
      ),
      key: "1",
      icon: <TbPokerChip className="mr-2 !text-2xl text-[#8e79ae]" />,
      size: "large",
    },
    {
      label: (
        <Link
          to="/products/Blockchain"
          onClick={onClose}
          className="text-base font-bold"
        >
          {i18n("Blockchain")}
        </Link>
      ),
      key: "4",
      icon: <SiBlockchaindotcom className="mr-2 !text-2xl text-[#8e79ae]" />,
      size: "large",
    },
    {
      label: (
        <Link
          to="/products/SICBO"
          onClick={onClose}
          className="text-base font-bold"
        >
          {i18n("SICBO")}
        </Link>
      ),
      key: "3",
      icon: <MdCasino className="mr-2 !text-2xl text-[#8e79ae]" />,
      size: "large",
    },
    // {
    //   label: (
    //     <Link
    //       to="/products/Fish"
    //       onClick={onClose}
    //       className="text-base font-bold"
    //     >
    //       {i18n("Fish")}
    //     </Link>
    //   ),
    //   key: "2",
    //   icon: <FaFish className="mr-2 !text-2xl" />,
    //   size: "large",
    // },
  ];

  return (
    <div className="flex flex-col py-4 gap-2 justify-between items-center md:hidden">
      {itemLinks.map((item, index) => {
        const isActive = location.pathname === item.to; // 判斷是否為當前頁面
        return (
          <Link
            to={item.to}
            onClick={onClose}
            className={`group flex rounded-full items-center menu-font bg-white w-full font-bold transition-colors duration-200 border-2 border-[#fcc8c6] ${
              isActive
                ? "text-black bg-hover-mask-bg hover-bg-transition"
                : "text-text-second-gray "
            } hover:text-black p-3 py-4 hover:bg-hover-mask-bg hover-bg-transition`}
          >
            {/* <span className="mr-2 text-2xl">{item.icon}</span> */}
            <img
              src={isActive ? item.activeImage : item.image}
              alt={item.text}
              className={`mr-2 w-6 h-6 ${isActive ? "" : "group-hover:hidden"}`}
            />
            <img
              src={item.activeImage}
              alt={item.text}
              className={`mr-2 w-6 h-6 ${
                isActive ? "hidden" : "hidden group-hover:block"
              }`}
            />

            <span
              className={`${
                isActive ? "text-black" : "group-hover:text-black"
              }`}
            >
              {item.text}
            </span>
          </Link>
        );
      })}
      {/* Products Dropdown */}
      <div className="w-full">
        <button
          onClick={() => toggleCategory("products")}
          className="relative z-[3] flex items-center justify-between w-full bg-white rounded-full px-4 py-3  transition-colors duration-200 border-2 border-[#fcc8c6]"
        >
          <div className="flex items-center gap-0">
            <img
              src={`${IMAGE_PATH}/product_active.webp`}
              alt="Products"
              className="mr-2 w-6 h-6"
            />
            <span className="text-text-second-gray font-bold hover:text-black">
              {i18n("Products")}
            </span>
          </div>
          {expandedCategory === "products" ? (
            <MdArrowDropUp className="w-8 h-8 text-text-second-gray" />
          ) : (
            <MdArrowDropDown className="w-8 h-8 text-text-second-gray" />
          )}
        </button>

        {expandedCategory === "products" && (
          <div className="relative mt-[-20px] pt-[20px] pr-1 pb-2 bg-[#fef0ef] rounded-b-[20px] border-2 border-[#fcc8c6]">
            <div className="p-2 space-y-1">
              {productItems.map((item) => (
                <Link
                  key={item.key}
                  to={item.label.props.to}
                  onClick={onClose}
                  className=" flex items-center gap-3 p-2 hover:bg-gray-50 rounded-lg"
                >
                  {item.icon}
                  <span className="text-base font-medium text-[#796794]">
                    {i18n(item.label.props.children)}
                  </span>
                </Link>
              ))}
            </div>
          </div>
        )}
      </div>
      {/* Company Dropdown */}
      <div className="w-full">
        <button
          onClick={() => toggleCategory("company")}
          className="relative z-[2] flex items-center justify-between w-full bg-white rounded-full px-4 py-3  transition-colors duration-200 border-2 border-[#fcc8c6]"
        >
          <div className="flex items-center gap-0">
            <img
              src={`${IMAGE_PATH}/company_active.webp`}
              alt="Company"
              className="mr-2 w-6 h-6"
            />
            <span className="text-text-second-gray font-bold hover:text-black">
              {i18n("Company")}
            </span>
          </div>
          {expandedCategory === "company" ? (
            <MdArrowDropUp className="w-8 h-8 text-text-second-gray" />
          ) : (
            <MdArrowDropDown className="w-8 h-8 text-text-second-gray" />
          )}
        </button>

        {expandedCategory === "company" && (
          <div className="relative mt-[-20px] pt-[20px] pr-1 pb-2 bg-[#fef0ef] rounded-b-[20px] border-2 border-[#fcc8c6]">
            <div className="p-2 space-y-1">
              {companyItems.map((item) => {
                const isActive = location.pathname === item.to; // 判斷是否為當前頁面
                return (
                  <Link
                    key={item.key}
                    to={item.label.props.to}
                    onClick={onClose}
                    className="flex items-center gap-3 p-2 hover:bg-gray-50 rounded-lg"
                  >
                    {/* {item.icon} */}
                    <img
                      src={isActive ? item.activeImage : item.image}
                      alt={item.text}
                      className={`mr-2 w-6 h-6 ${
                        isActive ? "" : "group-hover:hidden"
                      }`}
                    />
                    <img
                      src={item.activeImage}
                      alt={item.text}
                      className={`mr-2 w-6 h-6 ${
                        isActive ? "hidden" : "hidden group-hover:block"
                      }`}
                    />
                    <span className="text-base font-medium text-[#796794]">
                      {i18n(item.label.props.children)}
                    </span>
                  </Link>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MenuContentInner;
