import React from "react";
import MenuTop from "./MenuBar/MenuTop";
import Sidebar from "./Sidebar";
import Footer from "../../components/Footer";
import { useLocation } from "react-router-dom";

const MainLayout = ({ children }) => {
  const location = useLocation();
  const isHomePage = location.pathname === "/";

  return (
    <div className="md:min-h-screen">
      {/* Top Menu Bar */}
      <div className="bg-menu-background border-b-0 md:border-b-2 border-b-base-white px-3 md:px-4 lg:px-[68px] py-3 md:py-4 lg:py-[33px] fixed top-0 w-full z-10">
        <MenuTop />
      </div>

      {/* Main Content Area */}
      <div className="flex pt-[56px] md:pt-[103px] lg:pt-[126px]">
        {/* Left Sidebar - 只在首頁顯示 */}
        {isHomePage && (
          <div className="hidden md:block w-[280px] left-0 top-[126px] bottom-0 overflow-y-auto">
            <Sidebar />
          </div>
        )}

        {/* Right Content Area - 根據是否為首頁決定寬度 */}
        <div className={`flex-1 w-full ${isHomePage ? "md:ml-[0]" : ""}`}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
