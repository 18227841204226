import React from "react";
import {
  PiDevicesFill,
  PiGameControllerFill,
  PiWalletFill,
  PiCoinsFill,
} from "react-icons/pi";
import aboutBanner from "../../images/show/about_banner.webp";
import serviceAdmin from "../../images/show/service_admin.webp";
import serviceAPI from "../../images/show/service_API.webp";
import serviceCoin from "../../images/show/service_coin.webp";
import serviceOnline from "../../images/show/service_online.webp";
import serviceTech from "../../images/show/service_tech.webp";
import serviceWallet from "../../images/show/service_wallet.webp";
import { useTranslation } from "react-i18next";
import i18n from "../../../src/i18n/i18n";
import { LazyLoadImage } from "react-lazy-load-image-component";
import aboutBanner2 from "../../images/show/about_banner2.webp";
import aboutBanner2_mobile from "../../images/show/about_banner2_mobile.webp";
import systemICON from "../../images/show/system.webp";
import gameICON from "../../images/show/game.webp";
import walletICON from "../../images/show/wallet.webp";
import supportICON from "../../images/show/support.webp";
import { COMMON_WEB_PATH } from "../../helpers/stylesConfig";

const About = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`page.${key}`);
  const CAROUSEL_PATH = `${COMMON_WEB_PATH}/show`;

  const team = [
    {
      title: i18n("Professional"),
      text: i18n(
        "With years of industry experience, our team has mastered the secrets of popular games."
      ),
    },
    {
      title: i18n("Passion"),
      text: i18n(
        "We are passionate about games and committed to developing products that can become classics."
      ),
    },
    {
      title: i18n("Innovation"),
      text: i18n(
        "Eazy Gaming values innovation because it allows us to create unique and distinctive games."
      ),
    },
    {
      title: i18n("BMM testlabs"),
      text: i18n(
        "Our RNG games have received certification from BMM, a global leader in game testing. We meet all legal and technical requirements in most European jurisdictions."
      ),
    },
    {
      title: i18n("Customer"),
      text: i18n(
        "We are a game development company, and we always prioritize the customer's perspective, striving to develop games that can increase profits and create endless business opportunities."
      ),
    },
  ];

  const services = [
    {
      icon: <PiDevicesFill className="text-light-gray text-4xl" />,
      image: systemICON,
      text: i18n(
        "Our backend system provides real-time operational data and a variety of management tools to help you efficiently manage your business."
      ),
    },
    {
      icon: <PiGameControllerFill className="text-light-gray text-4xl" />,
      image: gameICON,
      text: i18n(
        "All of our game products can seamlessly integrate into your platform, offering you a wide variety of gaming choices."
      ),
    },
    {
      icon: <PiWalletFill className="text-light-gray text-4xl" />,
      image: walletICON,
      text: i18n(
        "We provide transfer wallet integration and single wallet integration solutions, allowing players to flexibly use their funds and enjoy convenient entertainment."
      ),
    },
    {
      icon: <PiCoinsFill className="text-light-gray text-4xl" />,
      image: supportICON,
      text: i18n(
        "Our game supports multiple major languages and currencies, allowing players from around the world to enjoy our game."
      ),
    },
  ];

  return (
    <section className="flex flex-col w-full min-h-[100vh] gap-6 p-4 md:p-12">
      <LazyLoadImage
        src={`${CAROUSEL_PATH}/about_banner2.webp`}
        className="w-full hidden md:block"
      />
      <LazyLoadImage
        src={`${CAROUSEL_PATH}/about_banner2_mobile.webp`}
        className="w-full md:hidden"
      />
      <div className="flex flex-col px-6 md:px-24 py-6">
        <h1 className="text-light-black-2 text-4xl md:text-6xl font-semibold text-center">
          {i18n("About Us")}
        </h1>
        <span className="text-text-second-gray text-lg font-normal text-center my-10">
          {i18n("about1")}
        </span>
      </div>
      <div className="flex flex-col px-6 md:px-24 py-6">
        <h1 className="text-light-black-2 text-4xl md:text-6xl font-semibold text-center">
          {i18n("Our Team")}
        </h1>
        <div className="flex flex-col justify-center items-center my-4">
          <div className="flex flex-col justify-start items-start">
            {team.map((team, index) => (
              <div key={index} className="flex flex-col my-4">
                <h2 className="text-light-black-2 font-medium text-xl border-l-8 border-[#ccacdd] lg:border-[#6019d9] pl-4">
                  {team.title}
                </h2>
                <span className="text-text-second-gray text-lg font-normal text-left my-4">
                  {team.text}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="flex flex-col px-0 lg:px-24 py-6 justify-center items-center">
        <h1 className="text-light-black-2 text-4xl md:text-6xl font-semibold text-center">
          {i18n("Service")}
        </h1>
        <div className="flex flex-col lg:flex-row justify-center items-center w-full gap-5 my-10 mb-0 lg:mb-10">
          {services.map((service, index) => (
            <div
              key={index}
              className="flex items-center w-full lg:w-[25%] h-auto lg:h-[300px] text-center rounded-[20px] lg:rounded-[80px] p-6 bg-gradient-to-b from-[#f1e8f6] to-[#d2b7e1]
     lg:from-[#71d4fd] lg:to-[#4ac1f2]"
            >
              <div className="flex flex-col justify-center items-center">
                {/* {service.icon} */}
                <img src={service.image} alt={service.text} />
                <p className="text-text-second-gray lg:text-white text-base font-normal text-center my-2">
                  {service.text}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default About;
