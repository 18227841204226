import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import { Link } from "react-router-dom";
import { carouselData } from "../helpers/carouselBannerList";
import { gameData } from "../helpers/gamelist";
import { useTranslation } from "react-i18next";
import i18n from "../../src/i18n/i18n";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { COMMON_ALT } from "../constant";

const CarouselV2 = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`carousel.${key}`);

  // 定義不同尺寸的文字陰影樣式
  const titleShadowStyle = {
    mobile: {
      textShadow: `-2px -2px 0 #f94c91,  
                    2px -2px 0 #f94c91,
                   -2px 2px 0 #f94c91,
                    2px 2px 0 #f94c91`,
    },
    desktop: {
      textShadow: `-2px -2px 0 #000,  
                    2px -2px 0 #000,
                   -2px 2px 0 #000,
                    2px 2px 0 #000`,
    },
  };

  return (
    <section className="md:px-4 lg:px-[54px] relative p-3 md:pt-4 lg:pt-8 pb-[20px] md:pb-4 lg:pb-[51px] flex justify-center">
      <Splide
        className="h-[80vw] md:h-[31vw] lg:h-[370px] w-full max-w-[1680px] overflow-hidden rounded-[40px] md:rounded-[56px] sm:menu-box-shadow"
        options={{
          type: "loop",
          perPage: 1,
          gap: 0,
          autoplay: false,
          arrows: true,
        }}
      >
        {carouselData.map((game, index) => (
          <SplideSlide key={index} className="relative">
            <LazyLoadImage
              alt={game.title}
              src={game.image}
              data-splide-lazy="path-to-the-image"
              className="object-cover w-full h-[143vw] md:h-[31vw] lg:h-[370px] relative hidden md:block"
            />
            <LazyLoadImage
              alt={game.title}
              src={game.imageMobile}
              data-splide-lazy="path-to-the-image"
              className="object-cover w-full h-[80vw] md:h-[31vw] lg:h-[370px] relative block md:hidden rounded-xl"
            />
            <div className="flex flex-col absolute w-full md:w-[57%] bottom-[69%] sm:bottom-[56%] md:bottom-[56%]">
              <div className=" flex flex-col gap-2 items-center">
                <LazyLoadImage
                  src={game.logo}
                  alt={game.title}
                  //   className="w-[180px] md:w-[350px]"
                  className="h-[80px] md:h-[160px] hidden md:block"
                  data-splide-lazy="path-to-the-image"
                />
                <div>
                  <div className="text-center pt-2">
                    <h3 className="text-white font-extrabold text-xl md:text-2xl mb-1 md:mb-2">
                      <span
                        className="md:hidden"
                        style={titleShadowStyle.mobile}
                      >
                        {game.Headtitle}
                      </span>
                      <span
                        className="hidden md:inline"
                        style={titleShadowStyle.desktop}
                      >
                        {game.Headtitle}
                      </span>
                    </h3>
                    <p
                      className="text-white font-normal text-sm md:text-xl"
                      style={{
                        textShadow: `-2px -2px 0 #1c1cd9,  
                                      2px -2px 0 #1c1cd9,
                                     -2px 2px 0 #1c1cd9,
                                      2px 2px 0 #1c1cd9`,
                      }}
                    >
                      {game.description}
                    </p>
                  </div>
                  <div className="flex justify-center items-center gap-3 pt-3">
                    <Link to={`/play/${game.uid}`}>
                      <div className="w-auto md:w-[150px] text-center bg-[#fd94cc] hover:bg-[#fd94b2] rounded-[12px] px-5 md:px-8 py-2 md:py-2.5 text-white border-2 md:border-4 border-white">
                        {i18n("Play Demo")}
                      </div>
                    </Link>
                    <Link to={game.Morelink}>
                      <div className="w-auto md:w-[150px] text-center gradient-button rounded-[12px] px-5 md:px-8 py-2 md:py-2.5 text-white border-2 md:border-4 border-white">
                        {i18n("Read More")}
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </SplideSlide>
        ))}
      </Splide>
    </section>
  );
};

export default CarouselV2;
