import React, { useState } from "react";
import { Link } from "react-router-dom";
import { gameData } from "../../helpers/gamelist";
import { IoChevronDownOutline, IoChevronUpOutline } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { COMMON_WEB_PATH, typeIconConfig } from "../../helpers/stylesConfig";
import { MdArrowDropDown, MdArrowDropUp } from "react-icons/md";

const GameCategoryList = () => {
  const { t, i18n: i18nInstance } = useTranslation();
  const i18n = (key) => t(`common.${key}`);

  const currentLang = i18nInstance.language || "en";

  // 獲取當前語言的遊戲數據
  const currentGameData = gameData(currentLang);

  // 新的圖片路徑配置
  const GAME_LIST_PATH = `${COMMON_WEB_PATH}/smallGameIcon/${currentLang}`;
  const GAME_IMAGES = {
    26: `${GAME_LIST_PATH}/pig.webp`,
    25: `${GAME_LIST_PATH}/tron.webp`,
    24: `${GAME_LIST_PATH}/bikini.webp`,
    23: `${GAME_LIST_PATH}/poseidon.webp`,
    22: `${GAME_LIST_PATH}/spa.webp`,
    21: `${GAME_LIST_PATH}/Napoleon.webp`,
    20: `${GAME_LIST_PATH}/hottie.webp`,
    19: `${GAME_LIST_PATH}/rabbitgirl.webp`,
    18: `${GAME_LIST_PATH}/goddess.webp`,
    17: `${GAME_LIST_PATH}/queen.webp`,
    16: `${GAME_LIST_PATH}/sister.webp`,
    15: `${GAME_LIST_PATH}/Wheel.webp`,
    14: `${GAME_LIST_PATH}/Plinko.webp`,
    13: `${GAME_LIST_PATH}/Hilo.webp`,
    12: `${GAME_LIST_PATH}/Dice.webp`,
    11: `${GAME_LIST_PATH}/blastx.webp`,
    10: `${GAME_LIST_PATH}/fishprawncrab.webp`,
    9: `${GAME_LIST_PATH}/xocdia.webp`,
    8: `${GAME_LIST_PATH}/sicbo.webp`,
    7: `${GAME_LIST_PATH}/holmes.webp`,
    6: `${GAME_LIST_PATH}/gidol.webp`,
    5: `${GAME_LIST_PATH}/goallinebaby.webp`,
    4: `${GAME_LIST_PATH}/nekomaid.webp`,
    3: `${GAME_LIST_PATH}/sinbad.webp`,
    2: `${GAME_LIST_PATH}/witch.webp`,
  };

  // 取得所有不重複的遊戲類型
  const gameCategories = [...new Set(currentGameData.map((game) => game.type))];

  // 管理每個類別的展開狀態
  const [expandedCategory, setExpandedCategory] = useState(null);

  const toggleCategory = (category) => {
    setExpandedCategory(expandedCategory === category ? null : category);
  };

  return (
    <div className="flex flex-col w-full gap-3">
      {gameCategories.map((category) => (
        <div key={category} className="w-full">
          {/* Category Button */}
          <button
            onClick={() => toggleCategory(category)}
            className="relative z-[2] flex items-center justify-between w-full bg-[#fed4d3] md:bg-[#fef0ef] rounded-full px-4 py-2 hover:bg-gray-50 transition-colors duration-200 border-2 border-[#fcc8c6]"
          >
            <div className="flex items-center gap-3 md:gap-6">
              <img
                src={typeIconConfig[category]}
                alt={category}
                className="w-6 h-6"
              />
              <span className="text-base text-white md:text-[#796794] font-medium">
                {i18n(category)}
              </span>
            </div>
            {expandedCategory === category ? (
              <MdArrowDropUp className="w-8 h-8 text-white md:text-[#796794]" />
            ) : (
              <MdArrowDropDown className="w-8 h-8 text-white md:text-[#796794]" />
            )}
          </button>

          {expandedCategory === category && (
            <div className="relative mt-[-20px] pt-[20px] pr-1 pb-2 bg-[#fef0ef] rounded-b-[20px] border-2 border-[#fcc8c6]">
              <div
                className="p-2 space-y-1 max-h-[300px] overflow-y-auto
            [&::-webkit-scrollbar]:w-2
            [&::-webkit-scrollbar-track]:rounded-full
            [&::-webkit-scrollbar-track]:bg-gray-100
            [&::-webkit-scrollbar-thumb]:rounded-full
            [&::-webkit-scrollbar-thumb]:bg-[#fcc8c6]"
              >
                {currentGameData
                  .filter((game) => game.type === category)
                  .map((game) => (
                    <Link
                      key={game.uid}
                      to={`/play/${game.uid}`}
                      className="flex items-center gap-3 p-2 hover:bg-gray-50 rounded-lg"
                    >
                      <img
                        src={GAME_IMAGES[game.uid] || game.image}
                        alt={game.title}
                        className="w-10 h-10 rounded-lg object-cover"
                      />
                      <span className="text-base font-medium text-[#796794]">
                        {i18n(game.title)}
                      </span>
                    </Link>
                  ))}
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
export default GameCategoryList;
