import neko from "../images/Carousel/neko3.webp";
import nekoLogo from "../images/Carousel/neko-logo.webp";
import nekoMobile from "../images/Carousel/neko-mobile3.webp";
import blastx from "../images/Carousel/blastx2.webp";
import blastxLogo from "../images/Carousel/blastx-logo.webp";
import blastxMobile from "../images/Carousel/blastx-mobile2.webp";
//import fish from "../images/Carousel/fish2.webp";
import fishLogo from "../images/Carousel/fish-logo.webp";
import fishMobile from "../images/Carousel/fish-mobile2.webp";
import goalbaby from "../images/Carousel/goalbaby2.webp";
import goalbabyLogo from "../images/Carousel/goalbaby-logo.webp";
import goalbabyMobile from "../images/Carousel/goalbaby-mobile2.webp";
import holmes from "../images/Carousel/holmes3.webp";
import holmesLogo from "../images/Carousel/holmes-logo.webp";
import holmesMobile from "../images/Carousel/holmes-mobile3.webp";
import sister from "../images/Carousel/sister2.webp";
import sisterLogo from "../images/Carousel/sister-logo.webp";
import sisterMobile from "../images/Carousel/sister-mobile2.webp";
import idol from "../images/Carousel/idol3.webp";
import idolLogo from "../images/Carousel/idol-logo.webp";
import idolMobile from "../images/Carousel/idol-mobile3.webp";
//import sicbo from "../images/Carousel/sicbo2.webp";
import sicboLogo from "../images/Carousel/sicbo-logo.webp";
import sicboMobile from "../images/Carousel/sicbo-mobile2.webp";
import simbad from "../images/Carousel/simbad2.webp";
import simbadLogo from "../images/Carousel/simbad-logo.webp";
import simbadMobile from "../images/Carousel/simbad-mobile2.webp";
import treasure from "../images/Carousel/treasure2.webp";
import treasureLogo from "../images/Carousel/treasure-logo.webp";
import treasureMobile from "../images/Carousel/treasure-mobile2.webp";
import witch from "../images/Carousel/witch2.webp";
import witchLogo from "../images/Carousel/witch-logo.webp";
import witchMobile from "../images/Carousel/witch-mobile2.webp";
//import xio from "../images/Carousel/xio2.webp";
import xioLogo from "../images/Carousel/xio-logo.webp";
import xioMobile from "../images/Carousel/xio-mobile2.webp";
import queen from "../images/Carousel/queen.webp";
import queenLogo from "../images/Carousel/queen-logo.webp";
import queenMobile from "../images/Carousel/queen-mobile.webp";
import goddess from "../images/Carousel/goddess3.webp";
import goddessLogo from "../images/Carousel/goddess-logo.webp";
import goddessMobile from "../images/Carousel/goddess-mobile2.webp";
import rabbitgirl from "../images/Carousel/rabbitgirl3.webp";
import rabbitgirlLogo from "../images/Carousel/rabbitgirl-logo.webp";
import rabbitgirlMobile from "../images/Carousel/rabbitgirl-mobile2.webp";
import hottieLogo from "../images/Carousel/hottie-logo.webp";
import hottie from "../images/Carousel/hottie.webp";
import hottieMobile from "../images/Carousel/hottie-mobile.webp";
import spa from "../images/Carousel/spa3.webp";
import spaLogo from "../images/Carousel/spa-logo.webp";
import spaMobile from "../images/Carousel/spa-mobile2.webp";
import Napoleon from "../images/Carousel/Napoleon.webp";
import NapoleonLogo from "../images/Carousel/Napoleon-logo.webp";
import NapoleonMobile from "../images/Carousel/Napoleon-mobile.webp";
import poseidon from "../images/Carousel/poseidon3.webp";
import poseidonLogo from "../images/Carousel/poseidon-logo.webp";
import poseidonMobile from "../images/Carousel/poseidon-mobile2.webp";
import bikini from "../images/Carousel/bikini.webp";
import bikiniLogo from "../images/Carousel/bikini-logo.webp";
import bikiniMobile from "../images/Carousel/bikini-mobile.webp";
import tron from "../images/Carousel/tron.webp";
import tronLogo from "../images/Carousel/tron-logo.webp";
import tronMobile from "../images/Carousel/tron-mobile.webp";
import pig from "../images/Carousel/pig.webp";
import pigLogo from "../images/Carousel/pig-logo.webp";
import pigMobile from "../images/Carousel/pig-mobile.webp";
import { COMMON_WEB_PATH } from "./stylesConfig";

const CAROUSEL_PATH = `${COMMON_WEB_PATH}/Carousel`;

export const carouselData = [
  {
    title: "Streaming Girl",
    uid: 19,
    image: `${CAROUSEL_PATH}/rabbitgirl3.webp`,
    logo: `${CAROUSEL_PATH}/rabbitgirl-logo.webp`,
    imageMobile: `${CAROUSEL_PATH}/rabbitgirl-mobile2.webp`,
    Headtitle: "The cute bunny lady appears!",
    description: "Play games in the exclusive live broadcast room!",
    Demolink:
      "https://d29juml4m9n88c.cloudfront.net/games/slot/streaminggirl/?curr=demo&hidefps=true&useIFrame=true&hideTxID=true",
    Morelink: "/products",
    type: "Slot",
    maxOdds: "15000 X",
    RTP: "97%",
    Resolution: "1680x720",
    DemoSiteRTP: "1247%",
  },
  {
    title: "Miss Holmes",
    uid: 7,
    image: `${CAROUSEL_PATH}/holmes3.webp`,
    logo: `${CAROUSEL_PATH}/holmes-logo.webp`,
    imageMobile: `${CAROUSEL_PATH}/holmes-mobile3.webp`,
    Headtitle: "Enjoy the intellectual showdown",
    description: "Help us reclaim the priceless crown!",
    Demolink:
      "https://d29juml4m9n88c.cloudfront.net/games/slot/missholmescoldcase/?lang=en&curr=usd&hidefps=true&useIFrame=true&hideTxID=true",
    Morelink: "/products",
    type: "Slot",
    maxOdds: "3333 X",
    RTP: "97%",
    Resolution: "1680x720",
    DemoSiteRTP: "320%",
  },
  {
    title: "Goddess Wonderland",
    uid: 18,
    image: `${CAROUSEL_PATH}/goddess3.webp`,
    logo: `${CAROUSEL_PATH}/goddess-logo.webp`,
    imageMobile: `${CAROUSEL_PATH}/goddess-mobile2.webp`,
    Headtitle: "Meet the Goddess of Wealth",
    description: "Step into a fairyland, and gain infinite money.",
    Demolink:
      "https://d29juml4m9n88c.cloudfront.net/games/slot/goddesswonderland/?curr=demo&hidefps=true&useIFrame=true&hideTxID=true",
    Morelink: "/products",
    type: "Slot",
    maxOdds: "5000 X",
    RTP: "97%",
    Resolution: "1680x720",
    DemoSiteRTP: "175%",
  },
  {
    title: "G-idol",
    uid: 6,
    image: `${CAROUSEL_PATH}/idol3.webp`,
    logo: `${CAROUSEL_PATH}/idol-logo.webp`,
    imageMobile: `${CAROUSEL_PATH}/idol-mobile3.webp`,
    Headtitle: "Enjoy charming rewards",
    description: "Join hands with Sweet Idols!",
    Demolink:
      "https://d29juml4m9n88c.cloudfront.net/games/slot/gidol/?lang=en&curr=usd&hidefps=true&useIFrame=true&hideTxID=true",
    Morelink: "/products",
    type: "Slot",
    maxOdds: "3333 X",
    RTP: "97%",
    Resolution: "1680x720",
  },
  {
    title: "NEKO MAID",
    uid: 4,
    image: `${CAROUSEL_PATH}/neko3.webp`,
    imageMobile: `${CAROUSEL_PATH}/neko-mobile3.webp`,
    logo: `${CAROUSEL_PATH}/neko-logo.webp`,
    Headtitle: "Crash Never Like Before",
    description: "Sweet Maids, Exclusive Experience!",
    Demolink:
      "https://d29juml4m9n88c.cloudfront.net/games/slot/nekomaid/?lang=en&curr=usd&hidefps=true&useIFrame=true&hideTxID=true",
    Morelink: "/products",
    type: "Slot",
    maxOdds: "5000 X",
    RTP: "97%",
    Resolution: "1680x720",
  },
  // {
  //   title: "Fortune Piggy Coin",
  //   uid: 26,
  //   image: pig,
  //   logo: pigLogo,
  //   imageMobile: pigMobile,
  //   Headtitle: "Three cute piggy pounces in blue, yellow and red.",
  //   description: "Waiting to collect coins for the featured rewards!",
  //   Demolink:
  //     "https://d29juml4m9n88c.cloudfront.net/games/slot/sl2401/?curr=demo&hidefps=true&useIFrame=true&hide=txID",
  //   Morelink: "/products",
  //   type: "Slot",
  //   maxOdds: "13350 X",
  //   RTP: "97%",
  //   Resolution: "720x1680",
  //   DemoSiteRTP: "97%",
  // },
  // {
  //   title: "Tron Century",
  //   uid: 25,
  //   image: tron,
  //   logo: tronLogo,
  //   imageMobile: tronMobile,
  //   Headtitle: "Women in tight spacesuits driving",
  //   description: "Racing among the stars, collecting energy to win big prizes!",
  //   Demolink:
  //     "https://d29juml4m9n88c.cloudfront.net/games/slot/troncentury/?curr=demo&hidefps=true&useIFrame=true&hideTxID=true",
  //   Morelink: "/products",
  //   type: "Slot",
  //   maxOdds: "2000 X",
  //   RTP: "97%",
  //   Resolution: "1680x720",
  //   DemoSiteRTP: "97%",
  // },
  // {
  //   title: "Bikini Revelry",
  //   uid: 24,
  //   image: bikini,
  //   logo: bikiniLogo,
  //   imageMobile: bikiniMobile,
  //   Headtitle: "The summer water park is cool and refreshing",
  //   description:
  //     "Come and play with the bikini girls and get lots of happiness!",
  //   Demolink:
  //     "https://d29juml4m9n88c.cloudfront.net/games/slot/bikinirevelry/?curr=demo&hidefps=true&useIFrame=true&hideTxID=true",
  //   Morelink: "/products",
  //   type: "Slot",
  //   maxOdds: "15000 X",
  //   RTP: "97%",
  //   Resolution: "1680x720",
  //   DemoSiteRTP: "1247%",
  // },
  {
    title: "Glory of Poseidon",
    uid: 23,
    image: `${CAROUSEL_PATH}/poseidon3.webp`,
    logo: `${CAROUSEL_PATH}/poseidon-logo.webp`,
    imageMobile: `${CAROUSEL_PATH}/poseidon-mobile2.webp`,
    Headtitle: "Holding a trident in his hand",
    description:
      " He is the god of oceans, and is about to bestow rich treasures!",
    Demolink:
      "https://d29juml4m9n88c.cloudfront.net/games/slot/gloryofposeidon/?curr=demo&hidefps=true&useIFrame=true&hideTxID=true",
    Morelink: "/products",
    type: "Slot",
    maxOdds: "15000 X",
    RTP: "97%",
    Resolution: "1680x720",
    DemoSiteRTP: "1247%",
  },
  {
    title: "Beauty SPA",
    uid: 22,
    image: `${CAROUSEL_PATH}/spa3.webp`,
    logo: `${CAROUSEL_PATH}/spa-logo.webp`,
    imageMobile: `${CAROUSEL_PATH}/spa-mobile2.webp`,
    Headtitle: "Bathe and drink with Japanese beauties",
    description:
      "Intertwined with the snow scenery to create a beautiful scene!",
    Demolink:
      "https://d29juml4m9n88c.cloudfront.net/games/slot/beautyspa/?curr=demo&hidefps=true&useIFrame=true&hideTxID=true",
    Morelink: "/products",
    type: "Slot",
    maxOdds: "15000 X",
    RTP: "97%",
    Resolution: "1680x720",
    DemoSiteRTP: "1247%",
  },
  // {
  //   title: "Napoléon",
  //   uid: 21,
  //   image: Napoleon,
  //   logo: NapoleonLogo,
  //   imageMobile: NapoleonMobile,
  //   Headtitle: "On the battlefield",
  //   description: "Napoleon commanded thousands of troops and brought victory!",
  //   Demolink:
  //     "https://d29juml4m9n88c.cloudfront.net/games/slot/napoleon/?curr=demo&hidefps=true&useIFrame=true&hideTxID=true",
  //   Morelink: "/products",
  //   type: "Slot",
  //   maxOdds: "2000 X",
  //   RTP: "97%",
  //   Resolution: "1680x720",
  //   DemoSiteRTP: "97%",
  // },
  // {
  //   title: "Dim Sum Hottie",
  //   uid: 20,
  //   image: hottie,
  //   logo: hottieLogo,
  //   imageMobile: hottieMobile,
  //   Headtitle: "Delicious meals are delivered to the table!",
  //   description: "Steaming steamers bring generous bonuses!",
  //   Demolink:
  //     "https://d29juml4m9n88c.cloudfront.net/games/slot/dimsumhottie/?curr=demo&hidefps=true&useIFrame=true&hideTxID=true",
  //   Morelink: "/products",
  //   type: "Slot",
  //   maxOdds: "2000 X",
  //   RTP: "97%",
  //   Resolution: "1680x720",
  //   DemoSiteRTP: "97%",
  // },

  // {
  //   title: "Queen of Casino",
  //   uid: 17,
  //   image: queen,
  //   logo: queenLogo,
  //   imageMobile: queenMobile,
  //   Headtitle: "Glamorous and thrilling casino journey",
  //   description:
  //     "Beautiful lady guides you into the dazzling world of the casino.",
  //   Demolink:
  //     "https://d29juml4m9n88c.cloudfront.net/games/slot/queenofcasino/?curr=usd&hidefps=true&useIFrame=true&hideTxID=true",
  //   Morelink: "/products",
  //   type: "Slot",
  //   maxOdds: "3333 X",
  //   RTP: "97%",
  //   Resolution: "1680x720",
  //   DemoSiteRTP: "320%",
  // },
  // {
  //   title: "Kitsune Sister",
  //   uid: 16,
  //   image: sister,
  //   logo: sisterLogo,
  //   imageMobile: sisterMobile,
  //   Headtitle: "Experience their magical powers!",
  //   description:
  //     "Entering the mysterious forest, the fairy fox sisters will accompany you.",
  //   Demolink:
  //     "https://d29juml4m9n88c.cloudfront.net/games/slot/kitsunesister/?curr=usd&hidefps=true&useIFrame=true&hideTxID=true",
  //   Morelink: "/products",
  //   type: "Slot",
  //   maxOdds: "2000 X",
  //   RTP: "97%",
  //   Resolution: "1680x720",
  //   DemoSiteRTP: "97%",
  // },

  // {
  //   title: "Goal Line Baby",
  //   uid: 5,
  //   image: goalbaby,
  //   logo: goalbabyLogo,
  //   imageMobile: goalbabyMobile,
  //   Headtitle: "Ignite your passion",
  //   description: "Win with Football Babies!",
  //   Demolink:
  //     "https://d29juml4m9n88c.cloudfront.net/games/slot/goallinebaby/?lang=en&curr=usd&hidefps=true&useIFrame=true&hideTxID=true",
  //   Morelink: "/products",
  //   type: "Slot",
  //   maxOdds: "3333 X",
  //   RTP: "97%",
  //   Resolution: "1680x720",
  //   DemoSiteRTP: "320%",
  // },

  // {
  //   title: "ADVENTURE OF SINBAD",
  //   uid: 3,
  //   image: simbad,
  //   logo: simbadLogo,
  //   imageMobile: simbadMobile,
  //   Headtitle: "Embark on a Voyage, Seek the Treasures",
  //   description: "Become a legendary adventurers!",
  //   Demolink:
  //     "https://d29juml4m9n88c.cloudfront.net/games/slot/adventureofsinbad/?lang=en&curr=usd&hidefps=true&useIFrame=true&hideTxID=true",
  //   Morelink: "/products",
  //   type: "Slot",
  //   maxOdds: "5000 X",
  //   RTP: "97%",
  //   Resolution: "1680x720",
  // },

  // {
  //   title: "Blast X",
  //   uid: 11,
  //   image: blastx,
  //   imageMobile: blastxMobile,
  //   logo: blastxLogo,
  //   Headtitle: "Exhilarating instant game",
  //   description: "Bring the most rewarding outcomes!",
  //   Demolink:
  //     "https://d29juml4m9n88c.cloudfront.net/games/crash/blastxp/?lang=en&curr=usd&useIFrame=true&hideTxID=true",
  //   Morelink: "/products",
  //   type: "CASUAL",
  //   maxOdds: "",
  //   RTP: "97%",
  //   Resolution: "720x1280",
  // },
  // {
  //   title: "Fish Pawn Crab Spin",
  //   uid: 10,
  //   image: fish,
  //   logo: fishLogo,
  //   imageMobile: fishMobile,
  //   Headtitle: "Win in your hands",
  //   description: "Wanna get lots of trophy?",
  //   Demolink:
  //     "https://d29juml4m9n88c.cloudfront.net/games/sicbo/hooheyhowvideo/?lang=en&curr=usd&useIFrame=true&hideTxID=true",
  //   Morelink: "/products",
  //   type: "SICBO",
  //   maxOdds: "",
  //   RTP: "92.11%",
  //   Resolution: "720x1280",
  // },
  // {
  //   title: "Sicbo Spin",
  //   uid: 8,
  //   image: sicbo,
  //   logo: sicboLogo,
  //   imageMobile: sicboMobile,
  //   Headtitle: "Challenge your luck",
  //   description: "Let's experience a big win!",
  //   Demolink:
  //     "https://d29juml4m9n88c.cloudfront.net/games/sicbo/sicbovideo/?lang=en&curr=usd&useIFrame=true&hideTxID=true",
  //   Morelink: "/products",
  //   type: "SICBO",
  //   maxOdds: "",
  //   RTP: "97.22%",
  //   Resolution: "720x1280",
  // },
  // {
  //   title: "Treasure King",
  //   uid: 1,
  //   image: treasure,
  //   logo: treasureLogo,
  //   imageMobile: treasureMobile,
  //   Headtitle: "Explore the depths of the ocean",
  //   description: "Unearth vast treasures and hidden technologies!",
  //   Demolink:
  //     "https://d29juml4m9n88c.cloudfront.net/games/fishing/treasureking/?lang=en&curr=usd&useIFrame=true",
  //   Morelink: "/products",
  //   type: "Slot",
  //   maxOdds: "5000 X",
  //   RTP: "97%",
  //   Resolution: "1680x720",
  // },
  // {
  //   title: "Witch's Love",
  //   uid: 2,
  //   image: witch,
  //   logo: witchLogo,
  //   imageMobile: witchMobile,
  //   Headtitle: "Crash Never Like Before",
  //   description: "Explore the mysterious power of love",
  //   Demolink:
  //     "https://d29juml4m9n88c.cloudfront.net/games/slot/witchlove/?lang=en&curr=usd&hidefps=true&useIFrame=true&hideTxID=true",
  //   Morelink: "/products",
  //   type: "Slot",
  //   maxOdds: "5000 X",
  //   RTP: "97%",
  //   Resolution: "1680x720",
  // },
  // {
  //   title: "Xoc Dia Spin",
  //   uid: 9,
  //   image: xio,
  //   logo: xioLogo,
  //   imageMobile: xioMobile,
  //   Headtitle: "Brave enough to join the game?",
  //   description: "Be the top-ranked dealer!",
  //   Demolink:
  //     "https://d29juml4m9n88c.cloudfront.net/games/sicbo/xocdiavideo/?lang=en&curr=usd&useIFrame=true&hideTxID=true",
  //   Morelink: "/products",
  //   type: "SICBO",
  //   maxOdds: "",
  //   RTP: "96%",
  //   Resolution: "720x1280",
  // },
];
