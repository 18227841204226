import React from "react";
import licensesBanner from "../../images/show/licenses_banner.webp";
import licensesBanner2 from "../../images/show/licenses_banner2.webp";
import licensesBanner_mobile from "../../images/show/licenses_banner2_mobile.webp";
import { useTranslation } from "react-i18next";
import i18n from "../../../src/i18n/i18n";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { COMMON_WEB_PATH } from "../../helpers/stylesConfig";

const Licenses = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`page.${key}`);
  const CAROUSEL_PATH = `${COMMON_WEB_PATH}/show`;

  return (
    <section className="flex flex-col w-full md:min-h-[100vh] gap-6 p-4 md:p-12">
      <LazyLoadImage
        src={`${CAROUSEL_PATH}/licenses_banner2.webp`}
        className="w-full hidden md:block"
      />
      <LazyLoadImage
        src={`${CAROUSEL_PATH}/licenses_banner2_mobile.webp`}
        className="w-full md:hidden"
      />
      <div className="flex flex-col px-6 md:px-24 py-6">
        <h1 className="text-light-black-2 text-4xl md:text-6xl font-semibold text-center">
          {i18n("Licenses")}
        </h1>
        <span className="text-text-second-gray text-lg font-normal text-center my-10">
          {i18n("licenses1")}
        </span>
      </div>
    </section>
  );
};

export default Licenses;
