import React, { useRef } from "react";
import planet from "../images/icon/planet.webp";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import { Link } from "react-router-dom";
import { HiMiniRocketLaunch } from "react-icons/hi2";
import { gameData } from "../helpers/gamelist";
import { useTranslation } from "react-i18next";
import i18n from "../../src/i18n/i18n";
import { LazyLoadImage } from "react-lazy-load-image-component";
import levelICON from "../images/icon/levelICON.webp";
import levelWORD from "../images/icon/levelWORD.webp";
import { COMMON_WEB_PATH } from "../helpers/stylesConfig";

const LevelUpV2 = ({ promo }) => {
  const { t, i18n: i18nInstance } = useTranslation();
  const i18n = (key) => t(`common.${key}`);
  const currentLang = i18nInstance.language || "en";
  const currentGameData = gameData(currentLang);

  const splideRef = useRef(null);
  const ICON_PATH = `${COMMON_WEB_PATH}/icon`;

  return (
    <section
      className="relative levelUp-bg-linear rounded-3xl p-4 flex gap-4 mt-[54px] md:hidden"
      style={{
        background: "rgb(149,224,253)",
        background:
          "linear-gradient(180deg, rgba(149,224,253,1) 0%, rgba(113,206,244,1) 100%)",
      }}
    >
      <div className="w-full flex flex-col md:flex-row justify-between items-center gap-3">
        <div className="flex flex-col text-center">
          <div className="w-full text-center flex justify-between items-center">
            <img
              src={`${ICON_PATH}/levelICON.webp`}
              alt="levelICON"
              className="move-up-down mt-[-54px] w-[120px] lg:w-[150px] xl:w-[180px]"
            />
            <img
              src={`${ICON_PATH}/levelWORD.webp`}
              alt="level"
              className=" mt-[-74px] w-[68%] lg:w-[60%] xl:w-[70%]"
            />
          </div>
          <h1 className="text-white text-2xl  font-custom">
            {/* <span className="text-main-color mr-2">{i18n("Level Up")}</span> */}
            {i18n("Your Business")}
          </h1>
          <h1 className="text-white text-2xl font-custom">
            {i18n("With")}
            <span className="text-white mx-2">Eazy Gaming</span>
            {i18n("Games")}
          </h1>
        </div>
        <div className="flex flex-row gap-4">
          <Splide
            ref={splideRef}
            className=" w-full overflow-hidden"
            options={{
              type: "slide",
              perPage: 4,
              gap: 15,
              autoplay: false,
              arrows: false,
              drag: "free",
              perMove: 1,
              pagination: false,
              lazyLoad: "nearby",
              dragMinThreshold: {
                mouse: 0,
                touch: 10,
              },
              preloadPages: 4,
              keyboard: false,
              breakpoints: {
                640: {
                  perPage: 2,
                },
                768: {
                  perPage: 4,
                },
                1024: {
                  perPage: 4,
                },
                1280: {
                  perPage: 4,
                },
              },
            }}
          >
            {currentGameData
              .filter((item) => (promo ? item.promo === promo : true))
              .map((game, index) => (
                <SplideSlide key={index} className="rounded-xl">
                  <div className="gameSlide-container">
                    <LazyLoadImage
                      src={game.image}
                      alt={game.title}
                      className="rounded-xl object-cover w-full h-[180px]"
                    />
                    <div className="overlay rounded-xl">
                      <div className="overlay-content">
                        <p>{i18n(game.title)}</p>
                        <Link to={`/play/${game.uid}`}>
                          <div className="play-button rounded-[5px] !px-[8px] !py-[6px] !mb-2">
                            {i18n("Play Demo")}
                          </div>
                        </Link>
                        <Link to={game.Morelink}>
                          <div className="more-button rounded-[5px] !px-[8px] !py-[6px]">
                            {i18n("Read More")}
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </SplideSlide>
              ))}
          </Splide>
        </div>
      </div>
    </section>
  );
};

export default LevelUpV2;
