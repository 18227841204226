export const COMMON_WEB_PATH = process.env.REACT_APP_AWS_PATH;

// import mainLogo from "../images/Logo/EG-LOGO.webp";
// import mainLogoWhite from "../images/Logo/EG-LOGO.webp";
// import mobileLogo from "../images/Logo/EG-Logo-only2.webp";
// import headerLogo from "../images/Logo/headerLogo.webp";
// import footerLogo from "../images/Logo/footerLogo.webp";
// import slotICON from "../images/icon/slotICON.webp";
// import blockchainICON from "../images/icon/blockchainICON.webp";
// import sicboICON from "../images/icon/sicboICON.webp";

export const stylesConfig = {
  mainLogo: `${COMMON_WEB_PATH}/Logo/EG-LOGO.webp`,
  mainLogoWhite: `${COMMON_WEB_PATH}/Logo/EG-LOGO.webp`,
  mobileLogo: `${COMMON_WEB_PATH}/Logo/EG-Logo-only2.webp`,
  headerLogo: `${COMMON_WEB_PATH}/Logo/headerLogo.webp`,
  footerLogo: `${COMMON_WEB_PATH}/Logo/footerLogo.webp`,
};

export const typeIconConfig = {
  Slot: `${COMMON_WEB_PATH}/icon/slotICON.webp`,
  Blockchain: `${COMMON_WEB_PATH}/icon/blockchainICON.webp`,
  SICBO: `${COMMON_WEB_PATH}/icon/sicboICON.webp`,
};
