import React from "react";
import SearchButton from "./SearchButton";
import GameCategoryList from "./GameCategoryList";
import LevelUpV2 from "../../components/LevelUp_v2";
import LevelUpV3 from "../../components/LevelUp_v3";

const Sidebar = () => {
  return (
    <div className="p-4 md:pt-4 lg:pt-8 h-full leftBG border-r-2 border-r-base-white">
      <div className="mb-4">
        <SearchButton />
      </div>
      <div className="mt-4">
        <GameCategoryList />
      </div>
      <div className="mt-4">
        <LevelUpV3 />
      </div>
    </div>
  );
};

export default Sidebar;
