import game1 from "../images/game/treasureking.webp";
import game2 from "../images/game/witch.webp";
import game3 from "../images/game/sinbad.webp";
import game4 from "../images/game/nekomaid.webp";
import game5 from "../images/game/goallinebaby.webp";
import game6 from "../images/game/gidol.webp";
import game7 from "../images/game/holmes.webp";
import game8 from "../images/game/sicbo.webp";
import game9 from "../images/game/xocdia.webp";
import game10 from "../images/game/fishprawncrab.webp";
import game11 from "../images/game/blastx.webp";
import game12 from "../images/game/Dice.webp";
import game13 from "../images/game/Hilo.webp";
import game14 from "../images/game/Plinko.webp";
import game15 from "../images/game/Wheel.webp";
import game16 from "../images/game/sister.webp";
import game17 from "../images/game/queen.webp";
import game18 from "../images/game/goddess.webp";
import game19 from "../images/game/rabbitgirl.webp";
import game20 from "../images/game/hottie.webp";
import game21 from "../images/game/Napoleon.webp";
import game22 from "../images/game/spa.webp";
import game23 from "../images/game/poseidon.webp";
import game24 from "../images/game/bikini.webp";
import game25 from "../images/game/tron.webp";
import game26 from "../images/game/pig.webp";
import { COMMON_WEB_PATH } from "./stylesConfig";

// 圖片路徑會根據當前語言動態生成
const getImagePath = (lang = "en") => `${COMMON_WEB_PATH}/GameIcon/${lang}`;
const getImagePath_mobile = (lang = "en") =>
  `${COMMON_WEB_PATH}/GameIcon/${lang}/mobile`;

export const gameData = (currentLang = "en") => {
  const GAME_PATH = getImagePath(currentLang);
  const GAME_MOBILE_PATH = getImagePath_mobile(currentLang);

  return [
    {
      title: "Fortune Piggy Coin",
      new: true,
      uid: 26,
      direction: "vertical",
      image: `${GAME_PATH}/pig.webp`,
      image_mobile: `${GAME_MOBILE_PATH}/pig.webp`,
      tag: "New",
      Demolink:
        "https://d29juml4m9n88c.cloudfront.net/games/slot/sl2401/?curr=demo&hidefps=true&useIFrame=true&hide=txID",
      Morelink: "/products",
      type: "Slot",
      maxOdds: "13350 X",
      RTP: "97%",
      Resolution: "720x1680",
      DemoSiteRTP: "97%",
      intro:
        "Three cute piggy pounces in blue, yellow and red are waiting to collect coins for the featured rewards. Mystery symbols may appear at any time, and bringing any combination of symbols or Fortune Piggy Coins - blue, yellow, and red - will trigger a featured free game and increase your chances of winning.",
    },
    {
      title: "Tron Century",
      new: true,
      uid: 25,
      direction: "horizontal",
      image: `${GAME_PATH}/tron.webp`,
      image_mobile: `${GAME_MOBILE_PATH}/tron.webp`,
      tag: "New",
      Demolink:
        "https://d29juml4m9n88c.cloudfront.net/games/slot/troncentury/?curr=demo&hidefps=true&useIFrame=true&hideTxID=true",
      Morelink: "/products",
      type: "Slot",
      maxOdds: "2000 X",
      RTP: "97%",
      Resolution: "1680x720",
      DemoSiteRTP: "97%",
      intro:
        "Women in tight spacesuits drive light wheels and race among the stars, collecting energy to win big prizes",
    },
    {
      title: "Bikini Revelry",
      new: true,
      uid: 24,
      direction: "horizontal",
      image: `${GAME_PATH}/bikini.webp`,
      image_mobile: `${GAME_MOBILE_PATH}/bikini.webp`,
      tag: "New",
      Demolink:
        "https://d29juml4m9n88c.cloudfront.net/games/slot/bikinirevelry/?curr=demo&hidefps=true&useIFrame=true&hideTxID=true",
      Morelink: "/products",
      type: "Slot",
      maxOdds: "15000 X",
      RTP: "97%",
      Resolution: "1680x720",
      DemoSiteRTP: "1247%",
      intro:
        "The summer water park is cool and refreshing. Come and play with the bikini girls and get lots of happiness and bonuses.",
    },
    {
      title: "Glory of Poseidon",
      new: true,
      uid: 23,
      direction: "horizontal",
      image: `${GAME_PATH}/poseidon.webp`,
      image_mobile: `${GAME_MOBILE_PATH}/poseidon.webp`,
      tag: "New",
      Demolink:
        "https://d29juml4m9n88c.cloudfront.net/games/slot/gloryofposeidon/?curr=demo&hidefps=true&useIFrame=true&hideTxID=true",
      Morelink: "/products",
      type: "Slot",
      maxOdds: "15000 X",
      RTP: "97%",
      Resolution: "1680x720",
      DemoSiteRTP: "1247%",
      intro:
        "Holding a trident in his hand, he is the god of oceans, wind and rain, and is about to bestow rich treasures.",
    },
    {
      title: "Beauty SPA",
      new: true,
      uid: 22,
      direction: "horizontal",
      image: `${GAME_PATH}/spa.webp`,
      image_mobile: `${GAME_MOBILE_PATH}/spa.webp`,
      tag: "New",
      Demolink:
        "https://d29juml4m9n88c.cloudfront.net/games/slot/beautyspa/?curr=demo&hidefps=true&useIFrame=true&hideTxID=true",
      Morelink: "/products",
      type: "Slot",
      maxOdds: "15000 X",
      RTP: "97%",
      Resolution: "1680x720",
      DemoSiteRTP: "1247%",
      intro:
        "In the snow-covered mountain hot springs, bathe and drink with Japanese beauties. The figures of the beauties bathing in the bath are looming in the steam, intertwined with the snow scenery to create a beautiful scene.",
    },
    {
      title: "Napoleon",
      uid: 21,
      direction: "horizontal",
      image: `${GAME_PATH}/Napoleon.webp`,
      image_mobile: `${GAME_MOBILE_PATH}/Napoleon.webp`,
      tag: "New",
      Demolink:
        "https://d29juml4m9n88c.cloudfront.net/games/slot/napoleon/?curr=demo&hidefps=true&useIFrame=true&hideTxID=true",
      Morelink: "/products",
      type: "Slot",
      maxOdds: "2000 X",
      RTP: "97%",
      Resolution: "1680x720",
      DemoSiteRTP: "97%",
      intro:
        "On the battlefield, Napoleon commanded thousands of troops and brought victory!",
    },
    {
      title: "Dim Sum Hottie",
      uid: 20,
      direction: "horizontal",
      image: `${GAME_PATH}/hottie.webp`,
      image_mobile: `${GAME_MOBILE_PATH}/hottie.webp`,
      tag: "New",
      Demolink:
        "https://d29juml4m9n88c.cloudfront.net/games/slot/dimsumhottie/?curr=demo&hidefps=true&useIFrame=true&hideTxID=true",
      Morelink: "/products",
      type: "Slot",
      maxOdds: "2000 X",
      RTP: "97%",
      Resolution: "1680x720",
      DemoSiteRTP: "97%",
      intro:
        "Delicious meals are delivered to the table, steaming steamers bring delicious Xiao Long Bao and generous bonuses!",
    },
    {
      title: "Streaming Girl",
      uid: 19,
      direction: "horizontal",
      image: `${GAME_PATH}/rabbitgirl.webp`,
      image_mobile: `${GAME_MOBILE_PATH}/rabbitgirl.webp`,
      tag: "New",
      Demolink:
        "https://d29juml4m9n88c.cloudfront.net/games/slot/streaminggirl/?curr=demo&hidefps=true&useIFrame=true&hideTxID=true",
      Morelink: "/products",
      type: "Slot",
      maxOdds: "15000 X",
      RTP: "97%",
      Resolution: "1680x720",
      DemoSiteRTP: "1247%",
      intro:
        "The cute bunny lady appears! Interact with fans and play games in the exclusive live broadcast room! Remember to subscribe to 'Streaming Girl™', mysterious surprise rewards are waiting for you!",
    },
    {
      title: "Goddess Wonderland",
      uid: 18,
      direction: "horizontal",
      image: `${GAME_PATH}/goddess.webp`,
      image_mobile: `${GAME_MOBILE_PATH}/goddess.webp`,
      tag: "New",
      Demolink:
        "https://d29juml4m9n88c.cloudfront.net/games/slot/goddesswonderland/?curr=demo&hidefps=true&useIFrame=true&hideTxID=true",
      Morelink: "/products",
      type: "Slot",
      maxOdds: "5000 X",
      RTP: "97%",
      Resolution: "1680x720",
      DemoSiteRTP: "175%",
      intro:
        "People seeking wealth go to temples, but they never expect to step into a fairyland, meet the Goddess of Wealth, and gain infinite money.",
    },
    {
      title: "Queen of Casino",
      uid: 17,
      direction: "horizontal",
      image: `${GAME_PATH}/queen.webp`,
      image_mobile: `${GAME_MOBILE_PATH}/queen.webp`,
      tag: "New",
      Demolink:
        "https://d29juml4m9n88c.cloudfront.net/games/slot/queenofcasino/?curr=usd&hidefps=true&useIFrame=true&hideTxID=true",
      Morelink: "/products",
      type: "Slot",
      maxOdds: "3333 X",
      RTP: "97%",
      Resolution: "1680x720",
      DemoSiteRTP: "210%",
      intro:
        "This is a glamorous and thrilling casino journey, where a mysterious and beautiful lady guides you into the dazzling world of the casino. Revealing a series of surprises with continuous jackpot possibilities.",
    },
    {
      title: "Kitsune Sister",
      uid: 16,
      direction: "horizontal",
      image: `${GAME_PATH}/sister.webp`,
      image_mobile: `${GAME_MOBILE_PATH}/sister.webp`,
      tag: "New",
      Demolink:
        "https://d29juml4m9n88c.cloudfront.net/games/slot/kitsunesister/?curr=usd&hidefps=true&useIFrame=true&hideTxID=true",
      Morelink: "/products",
      type: "Slot",
      maxOdds: "2000 X",
      RTP: "97%",
      Resolution: "1680x720",
      DemoSiteRTP: "97%",
      intro:
        "Entering the mysterious forest, the fairy fox sisters will accompany you. Experience their magical powers and challenge your luck for a chance to discover exciting rewards.",
    },
    {
      title: "Miss Holmes",
      uid: 7,
      direction: "horizontal",
      image: `${GAME_PATH}/holmes.webp`,
      image_mobile: `${GAME_MOBILE_PATH}/holmes.webp`,
      tag: "New",
      promo: "Promo",
      Demolink:
        "https://d29juml4m9n88c.cloudfront.net/games/slot/missholmescoldcase/?curr=usd&hidefps=true&useIFrame=true&hideTxID=true",
      Morelink: "/products",
      type: "Slot",
      maxOdds: "3333 X",
      RTP: "97%",
      Resolution: "1680x720",
      DemoSiteRTP: "210%",
      intro:
        "Enjoy the intellectual showdown between Miss Sherlock and her arch-nemesis Moriarty. Uncover the conspiracy hidden behind, and reclaim the priceless crown.",
    },
    {
      title: "Goal Line Baby",
      uid: 5,
      direction: "horizontal",
      image: `${GAME_PATH}/goallinebaby.webp`,
      image_mobile: `${GAME_MOBILE_PATH}/goallinebaby.webp`,
      Demolink:
        "https://d29juml4m9n88c.cloudfront.net/games/slot/goallinebaby/?curr=usd&hidefps=true&useIFrame=true&hideTxID=true",
      Morelink: "/products",
      type: "Slot",
      maxOdds: "3333 X",
      RTP: "97%",
      Resolution: "1680x720",
      DemoSiteRTP: "210%",
      intro: "Ignite your passion and win with Football Babies!",
    },
    {
      title: "G-idol",
      uid: 6,
      direction: "horizontal",
      image: `${GAME_PATH}/gidol.webp`,
      image_mobile: `${GAME_MOBILE_PATH}/gidol.webp`,
      tag: "New",
      promo: "Promo",
      Demolink:
        "https://d29juml4m9n88c.cloudfront.net/games/slot/gidol/?curr=usd&hidefps=true&useIFrame=true&hideTxID=true",
      Morelink: "/products",
      type: "Slot",
      maxOdds: "3333 X",
      RTP: "97%",
      Resolution: "1680x720",
      DemoSiteRTP: "210%",
      intro: "Join hands with sweet idols and enjoy charming rewards!",
    },
    // {
    //   title: "Treasure King",
    //   uid: 1,
    //   direction: "vertical",
    //   image: game1,
    //   Demolink:
    //     "https://d29juml4m9n88c.cloudfront.net/games/fishing/treasureking/?curr=usd&useIFrame=true",
    //   Morelink: "/products",
    //   type: "Fish",
    //   maxOdds: "5000 X",
    //   RTP: "97%",
    //   Resolution: "1680x720",
    //DemoSiteRTP: "",
    //   intro:
    //     "Explore the depths of the ocean and uncover the mysteries of an ancient civilization. Pilot a formidable submarine, and unearth vast treasures and hidden technologies.",
    // },
    {
      title: "Witch's Love",
      uid: 2,
      direction: "horizontal",
      image: `${GAME_PATH}/witch.webp`,
      image_mobile: `${GAME_MOBILE_PATH}/witch.webp`,
      tag: "New",
      Demolink:
        "https://d29juml4m9n88c.cloudfront.net/games/slot/witchlove/?curr=usd&hidefps=true&useIFrame=true&hideTxID=true",
      Morelink: "/products",
      type: "Slot",
      maxOdds: "5000 X",
      RTP: "97%",
      Resolution: "1680x720",
      DemoSiteRTP: "175%",
      intro:
        "Explore the mysterious power of love as you step into the Witch's Love™ enchanted cottage, you will witness the birth of magical romance! The witch, skilled in brewing various potions, has elevated her magical studies to new heights, enchanting everyone who comes into contact with her love elixirs. Are you ready to embark on this enchanting journey and seek your own treasure in this game?",
    },
    {
      title: "ADVENTURE OF SINBAD",
      uid: 3,
      direction: "horizontal",
      image: `${GAME_PATH}/sinbad.webp`,
      image_mobile: `${GAME_MOBILE_PATH}/sinbad.webp`,
      tag: "New",
      Demolink:
        "https://d29juml4m9n88c.cloudfront.net/games/slot/adventureofsinbad/?curr=usd&hidefps=true&useIFrame=true&hideTxID=true",
      Morelink: "/products",
      type: "Slot",
      maxOdds: "5000 X",
      RTP: "97%",
      Resolution: "1680x720",
      DemoSiteRTP: "175%",
      intro:
        "Embark on a Voyage, Seek the Treasures of the Seven Seas! In Adventure of Sinbad™, you will become legendary adventurers whose names will be remembered for eternity!",
    },
    {
      title: "NEKO MAID",
      uid: 4,
      direction: "horizontal",
      image: `${GAME_PATH}/nekomaid.webp`,
      image_mobile: `${GAME_MOBILE_PATH}/nekomaid.webp`,
      tag: "New",
      Demolink:
        "https://d29juml4m9n88c.cloudfront.net/games/slot/nekomaid/?curr=usd&hidefps=true&useIFrame=true&hideTxID=true",
      Morelink: "/products",
      type: "Slot",
      maxOdds: "5000 X",
      RTP: "97%",
      Resolution: "1680x720",
      DemoSiteRTP: "175%",
      intro:
        "Sweet Maids, Exclusive Experience! The Neko Maid™ warmly invites you to step into their dreamy café. Infusing your meals with love and immersing you in a sweet gaming experience.",
    },

    {
      title: "Sicbo Spin",
      uid: 8,
      direction: "vertical",
      image: `${GAME_PATH}/sicbo.webp`,
      image_mobile: `${GAME_MOBILE_PATH}/sicbo.webp`,
      promo: "Promo",
      Demolink:
        "https://d29juml4m9n88c.cloudfront.net/games/sicbo/sicbovideo/?curr=usd&useIFrame=true&hideTxID=true",
      Morelink: "/products",
      type: "SICBO",
      maxOdds: "180X",
      RTP: "97.22%",
      Resolution: "720x1280",
      intro:
        "Challenge your luck and discover the path to becoming a winner with the Lucky Dice from the Golden Wheel. Diverse betting strategies allow players to experience a big win!",
    },
    {
      title: "Xoc Dia Spin",
      uid: 9,
      direction: "vertical",
      image: `${GAME_PATH}/xocdia.webp`,
      image_mobile: `${GAME_MOBILE_PATH}/xocdia.webp`,
      Demolink:
        "https://d29juml4m9n88c.cloudfront.net/games/sicbo/xocdiavideo/?curr=usd&useIFrame=true&hideTxID=true",
      Morelink: "/products",
      type: "SICBO",
      maxOdds: "12X",
      RTP: "96%",
      Resolution: "720x1280",
      intro: "Be the top-ranked dealer!!!",
    },
    {
      title: "Fish Pawn Crab Spin",
      uid: 10,
      direction: "vertical",
      image: `${GAME_PATH}/fishprawncrab.webp`,
      image_mobile: `${GAME_MOBILE_PATH}/fishprawncrab.webp`,
      Demolink:
        "https://d29juml4m9n88c.cloudfront.net/games/sicbo/hooheyhowvideo/?curr=usd&useIFrame=true&hideTxID=true",
      Morelink: "/products",
      type: "SICBO",
      maxOdds: "180X",
      RTP: "92.11%",
      Resolution: "720x1280",
      intro: "Win in your hands!!!",
    },
    {
      title: "Blast X",
      uid: 11,
      direction: "vertical",
      image: `${GAME_PATH}/blastx.webp`,
      image_mobile: `${GAME_MOBILE_PATH}/blastx.webp`,
      tag: "New",
      Demolink:
        "https://d29juml4m9n88c.cloudfront.net/games/crash/blastxp/?curr=usd&useIFrame=true&hideTxID=true",
      Morelink: "/products",
      type: "Blockchain",
      maxOdds: "10000X",
      RTP: "99%",
      Resolution: "720x1280",
      intro:
        "Experience the most exhilarating fast-paced instant game! Hoping your choices will bring the most rewarding outcomes!",
    },
    {
      title: "Dice",
      uid: 12,
      direction: "vertical",
      image: `${GAME_PATH}/Dice.webp`,
      image_mobile: `${GAME_MOBILE_PATH}/Dice.webp`,
      Demolink:
        "https://d29juml4m9n88c.cloudfront.net/games/crash/dicep/?curr=usd&useIFrame=true&hideTxID=true",
      Morelink: "/products",
      type: "Blockchain",
      maxOdds: "10000X",
      RTP: "98.33%",
      Resolution: "720x1280",
      intro: "Let's roll the dice!",
    },
    {
      title: "HiLo",
      uid: 13,
      direction: "vertical",
      image: `${GAME_PATH}/Hilo.webp`,
      image_mobile: `${GAME_MOBILE_PATH}/Hilo.webp`,
      Demolink:
        "https://d29juml4m9n88c.cloudfront.net/games/crash/hilop/?curr=usd&useIFrame=true&hideTxID=true",
      Morelink: "/products",
      type: "Blockchain",
      maxOdds: "10000X",
      RTP: "99%",
      Resolution: "720x1280",
      intro: "Embrace the thrilling moment of flipping cards!",
    },
    {
      title: "Plinko",
      uid: 14,
      direction: "vertical",
      image: `${GAME_PATH}/Plinko.webp`,
      image_mobile: `${GAME_MOBILE_PATH}/Plinko.webp`,
      tag: "New",
      promo: "Promo",
      Demolink:
        "https://d29juml4m9n88c.cloudfront.net/games/crash/plinkop/?curr=usd&useIFrame=true&hideTxID=true",
      Morelink: "/products",
      type: "Blockchain",
      maxOdds: "1000X",
      RTP: "97%",
      Resolution: "720x1280",
      intro: "Hoping ball lands in the highest payout slot!",
    },
    {
      title: "Wheel",
      uid: 15,
      direction: "vertical",
      image: `${GAME_PATH}/Wheel.webp`,
      image_mobile: `${GAME_MOBILE_PATH}/Wheel.webp`,
      Demolink:
        "https://d29juml4m9n88c.cloudfront.net/games/crash/wheelp/?curr=usd&useIFrame=true&hideTxID=true",
      Morelink: "/products",
      type: "Blockchain",
      maxOdds: "50X",
      RTP: "99%",
      Resolution: "720x1280",
      intro: "Stare at the Spinning Wheel and get the prize!",
    },
  ];
};
