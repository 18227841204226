import React, { useContext, useMemo } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { stylesConfig } from "../../../helpers/stylesConfig";
import MenuItem from "./MenuItem";
import LanguageSettings from "../../components/LanguageSettings";
import MenuMobile from "../../../components/MenuMobile";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n/i18n";
import { ContactScrollContext } from "../../../store/triggerScrollToContact";
import { COMMON_ALT } from "../../../constant";
import SearchButton from "../SearchButton";

const MenuTop = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`header.${key}`);

  const location = useLocation();
  const navigate = useNavigate();

  const isHomePage = useMemo(() => {
    if (location.pathname === "/") {
      return true;
    } else {
      return false;
    }
  }, [location.pathname]);

  const scrollCtx = useContext(ContactScrollContext);
  return (
    <div className="flex justify-between items-center gap-4">
      <Link to="/">
        <img
          alt={COMMON_ALT}
          src={stylesConfig.headerLogo}
          className="w-[100px] cursor-pointer mobile_show"
        />
        <img
          alt={COMMON_ALT}
          src={stylesConfig.headerLogo}
          className="w-[150px] cursor-pointer logo-drop-shadow pc_show"
        />
      </Link>
      <div className="block md:hidden w-[58%]">
        <SearchButton />
      </div>

      <div className="hidden md:flex justify-between items-center gap-8">
        <MenuItem />
        <div className="hidden md:flex justify-between items-center gap-[5px] p-[5px] rounded-[15px] bg-[#ffebec]">
          <section className="pc_show">
            <LanguageSettings />
          </section>
          <div
            onClick={() => {
              if (isHomePage) {
                scrollCtx.trigger();
              } else {
                navigate("/");
                scrollCtx.trigger();
              }
            }}
            className="gradient-button text-white cursor-pointer text-xs md:text-base font-bold blue-button px-3 md:px-5 py-1.5 md:py-2.5 rounded-[12px] hover-bg-transition"
          >
            {i18n("Contact")}
          </div>
        </div>
      </div>
      <MenuMobile />
    </div>
  );
};

export default MenuTop;
