import React from "react";
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  MailruIcon,
  OKShareButton,
  TelegramShareButton,
  TelegramIcon,
  LineIcon,
} from "react-share";
import { PiTelegramLogoLight } from "react-icons/pi";
import { IoMailOutline } from "react-icons/io5";
import telegram from "../images/icon/telegram.webp";
import email from "../images/icon/mail.webp";

const socialButtons = [
  {
    Component: TelegramShareButton,
    url: "https://t.me/MarsM8899",
    quote: "EAZY Gaming, leading the Global Gaming Market Trend!",
    hashtag: "EAZYGaming",
    IconComponent: TelegramIcon,
  },
  // {
  //   Component: EmailShareButton,
  //   url: "service@egslot.net",
  //   quote: "EAZY Gaming, leading the Global Gaming Market Trend!",
  //   hashtag: "EAZYGaming",
  //   IconComponent: EmailIcon,
  //   to: "service@egslot.net",
  // },
];
const links = [
  {
    href: "https://t.me/MarsM8899",
    className:
      "hover:scale-110 w-[50px] h-[50px] flex justify-center items-center",
    // icon: <PiTelegramLogoLight className="text-xl text-white font-bold" />,
    icon: (
      <img
        src={telegram}
        alt="telegram"
        className="w-full h-full object-contain"
      />
    ),
    rel: "noreferrer",
    target: "_blank",
  },
  {
    href: "mailto:service@egslot.net",
    className:
      "hover:scale-110 w-[50px] h-[50px] flex justify-center items-center",
    // icon: <IoMailOutline className="text-lg text-white font-bold" />,
    icon: (
      <img src={email} alt="email" className="w-full h-full object-contain" />
    ),
  },
];

const ShareButton = () => {
  return (
    <div className="flex gap-4 justify-center items-center">
      {/* {socialButtons.map((button, index) => (
        <button.Component
          key={index}
          className="hover:scale-110"
          url={button.url}
          quote={button.quote}
          hashtag={button.hashtag}
        >
          <button.IconComponent size={32} round />
        </button.Component>
      ))} */}
      {links.map((link, index) => (
        <a
          key={index}
          href={link.href}
          className={link.className}
          rel={link.rel}
          target={link.target}
        >
          {link.icon}
        </a>
      ))}
    </div>
  );
};

export default ShareButton;
