import React from "react";
import { Link } from "react-router-dom";
import {
  IoHome,
  IoGameController,
  IoPlanet,
  IoTrophy,
  IoChevronForwardOutline,
  IoChevronDownOutline,
} from "react-icons/io5";
import { PiCertificateFill } from "react-icons/pi";
import { LuExternalLink } from "react-icons/lu";
import { BsShieldFillCheck } from "react-icons/bs";
import license from "../images/icon/licenses.webp";
import provablyFair from "../images/icon/provably-fair.webp";
import tournament from "../images/icon/tournaments.webp";
import { useTranslation } from "react-i18next";
import i18n from "../../src/i18n/i18n";
import { LazyLoadImage } from "react-lazy-load-image-component";
import proveBG from "../images/bg/proveBG.webp";
import proveBG_mobile from "../images/bg/proveBG_mobile.webp";
import { COMMON_WEB_PATH } from "../helpers/stylesConfig";

const FeatureV2 = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`common.${key}`);
  const IMAGE_PATH = `${COMMON_WEB_PATH}/bg`;
  const ICON_PATH = `${COMMON_WEB_PATH}/icon`;

  const featureData = [
    {
      icon: <PiCertificateFill className="text-white text-4xl" />,
      imageSrc: `${ICON_PATH}/licenses.webp`,
      title: i18n("Licenses"),
      description: i18n(
        "Play with Assurance Backed by Our Industry's Most Respected Certifications"
      ),
      link: "/company/licenses",
    },
    {
      icon: <IoTrophy className="text-white text-4xl" />,
      imageSrc: `${ICON_PATH}/tournaments.webp`,
      title: i18n("About Us"),
      description: i18n(
        "Eazy Gaming was created by experienced and passionate industry experts"
      ),
      link: "/company/about",
    },
    {
      icon: <BsShieldFillCheck className="text-white text-4xl" />,
      imageSrc: `${ICON_PATH}/provably-fair.webp`,
      title: i18n("Provably Fair"),
      description: i18n(
        "Our cryptographic technology ensures 100% trust and transparency for players."
      ),
      link: "/company/provablyFair",
    },
  ];

  return (
    <div className="flex flex-col xl:flex-row gap-1 xl:gap-4 justify-center xl:justify-around items-stretch xl:items-start">
      {featureData.map((feature, index) => (
        <div
          key={index}
          className="relative flex-1 mb-0 xl:mb-4 md:mb-0 pt-0 xl:pt-[10px]"
        >
          <div className="hidden xl:flex relative xl:absolute w-full left-1/2 -translate-x-1/2 -top-2 z-[2] flex-row gap-2 justify-center items-center">
            <div className="w-[60px] lg:w-[70px] xl:w-[90px] h-[60px] lg:h-[70px] xl:h-[90px] flex items-center justify-center ">
              <LazyLoadImage
                src={feature.imageSrc}
                alt={feature.title}
                className="w-full h-full object-contain"
              />
            </div>
            <h2 className="text-white text-lg lg:text-xl xl:text-2xl font-medium py-2 hidden xl:block">
              {feature.title}
            </h2>
          </div>

          <div
            className={`
                flex xl:block gap-4 relative w-full min-h-[100px] h-auto xl:h-[230px] 
                pt-2 md:pt-6 xl:pt-[90px] px-3 md:px-6 xl:px-[20%] pb-2 md:pb-6 xl:pb-[20px] rounded-3xl xl:rounded-none
                bg-[image:var(--mobile-bg-image)]
                xl:bg-[image:var(--desktop-bg-image)]
              `}
            style={{
              "--mobile-bg-image": `url(${IMAGE_PATH}/proveBG_mobile.webp)`,
              "--desktop-bg-image": `url(${IMAGE_PATH}/proveBG.webp)`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "100% 100%",
            }}
          >
            <div className="flex xl:hidden w-[120px] lg:w-[70px] xl:w-[90px] h-[120px] lg:h-[70px] xl:h-[90px] items-center justify-center ">
              <LazyLoadImage
                src={feature.imageSrc}
                alt={feature.title}
                className="w-full h-full object-contain"
              />
            </div>
            <div className="flex flex-col gap-1 ">
              <h2 className=" block xl:hidden text-white text-[24px] font-medium">
                {feature.title}
              </h2>
              <p className="text-white text-base xl:text-xl leading-tight">
                {feature.description}
              </p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FeatureV2;
