import React from "react";
import { IoSearchOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import searchICON from "../../images/icon/searchICON.webp";
import searchICON_mobile from "../../images/icon/searchICON_mobile.webp";
import { COMMON_WEB_PATH } from "../../helpers/stylesConfig";
import { useTranslation } from "react-i18next";

const SearchButton = () => {
  const { t, i18n: i18nInstance } = useTranslation();
  const i18n = (key) => t(`page.${key}`);
  const currentLang = i18nInstance.language || "en";
  const navigate = useNavigate();
  const ICON_PATH = `${COMMON_WEB_PATH}/icon`;

  const handleClick = () => {
    navigate("/products");
  };

  return (
    <button
      onClick={handleClick}
      className="flex items-center gap-3 md:gap-6 w-[100%] md:w-full bg-[#fed4d3] md:bg-[#fef0ef] rounded-full px-3 md:px-4 py-3  transition-colors duration-200  border-2 border-[#fcc8c6]"
    >
      <img
        src={`${ICON_PATH}/searchICON_mobile.webp`}
        alt="search"
        className="w-6 h-6 block md:hidden"
      />
      <img src={searchICON} alt="search" className="w-6 h-6 hidden md:block" />
      <span className="text-base text-white md:text-[#796794] font-medium">
        {i18n("Search Games")}
      </span>
    </button>
  );
};

export default SearchButton;
