import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";
import { gameData } from "../helpers/gamelist";
import levelICON_mobile from "../images/icon/levelICON_mobile.webp";
import { COMMON_WEB_PATH } from "../helpers/stylesConfig";

const LevelUpV3 = ({ promo }) => {
  const { t, i18n: i18nInstance } = useTranslation();
  const i18n = (key) => t(`common.${key}`);

  const splideRef = useRef(null);
  const [randomGames, setRandomGames] = useState([]);
  // 根據當前語言設置圖片路徑
  const currentLang = i18nInstance.language || "en";
  const IMAGE_PATH = `${COMMON_WEB_PATH}/levelupGameIcon/${currentLang}`;
  const ICON_PATH = `${COMMON_WEB_PATH}/icon`;

  const GAME_IMAGES = {
    26: `${IMAGE_PATH}/pig.webp`,
    25: `${IMAGE_PATH}/tron.webp`,
    24: `${IMAGE_PATH}/bikini.webp`,
    23: `${IMAGE_PATH}/poseidon.webp`,
    22: `${IMAGE_PATH}/spa.webp`,
    21: `${IMAGE_PATH}/Napoleon.webp`,
    20: `${IMAGE_PATH}/hottie.webp`,
    19: `${IMAGE_PATH}/rabbitgirl.webp`,
    18: `${IMAGE_PATH}/goddess.webp`,
    17: `${IMAGE_PATH}/queen.webp`,
    16: `${IMAGE_PATH}/sister.webp`,
    15: `${IMAGE_PATH}/Wheel.webp`,
    14: `${IMAGE_PATH}/Plinko.webp`,
    13: `${IMAGE_PATH}/Hilo.webp`,
    12: `${IMAGE_PATH}/Dice.webp`,
    11: `${IMAGE_PATH}/blastx.webp`,
    10: `${IMAGE_PATH}/fishprawncrab.webp`,
    9: `${IMAGE_PATH}/xocdia.webp`,
    8: `${IMAGE_PATH}/sicbo.webp`,
    7: `${IMAGE_PATH}/holmes.webp`,
    6: `${IMAGE_PATH}/gidol.webp`,
    5: `${IMAGE_PATH}/goallinebaby.webp`,
    4: `${IMAGE_PATH}/nekomaid.webp`,
    3: `${IMAGE_PATH}/sinbad.webp`,
    2: `${IMAGE_PATH}/witch.webp`,
  };

  useEffect(() => {
    const currentGameData = gameData(currentLang);

    const getRandomGames = () =>
      [...currentGameData].sort(() => 0.5 - Math.random()).slice(0, 3);

    setRandomGames(getRandomGames());
    const interval = setInterval(() => setRandomGames(getRandomGames()), 10000);
    return () => clearInterval(interval);
  }, [currentLang]);

  return (
    <section className="relative bg-white rounded-3xl p-4 pt-2 flex gap-4 mt-10">
      <div className="w-full flex flex-col justify-between items-center gap-1">
        <div className="flex flex-col text-center">
          <div className="w-full text-center flex justify-between items-center">
            <img
              src={`${ICON_PATH}/levelICON_mobile.webp`}
              alt="levelICON"
              className="move-up-down mt-[-14px] w-[80px]"
            />
            <div>
              <h1 className="text-[#ff644d] text-3xl font-custom">
                {i18n("Level Up")}
              </h1>
              <h1 className="text-[#796794] text-xs font-custom">
                {i18n("Your Business")} {i18n("With")}
              </h1>
            </div>
          </div>
        </div>
        <h1 className="text-[#796794] text-xl  font-custom">
          <span className="text-[#ff644d] mx-2">Eazy Gaming</span>
          {i18n("Games")}
        </h1>
        <div className="flex flex-col gap-2 w-full">
          {randomGames.map((game) => (
            <div key={game.uid} className="gameSlide-container">
              <LazyLoadImage
                src={GAME_IMAGES[game.uid] || game.image}
                alt={game.title}
                className="rounded-xl object-cover w-full h-[150px]"
              />
              <div className="overlay rounded-xl">
                <div className="overlay-content">
                  <p>{i18n(game.title)}</p>
                  <Link to={`/play/${game.uid}`}>
                    <div className="play-button rounded-[5px] !px-[8px] !py-[6px] !mb-2">
                      {i18n("Play Demo")}
                    </div>
                  </Link>
                  <Link to={game.Morelink}>
                    <div className="more-button rounded-[5px] !px-[8px] !py-[6px]">
                      {i18n("Read More")}
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default LevelUpV3;
